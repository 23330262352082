import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { checkOrder } from "../../utils/payments";

export function Order(props) {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  const orderid = props.order_id;
  const lang = props.lang;
  console.log("orderid", orderid, "lang:", lang);

  let history = useHistory();
  useEffect(() => {
    checkOrder(orderid).then((res) => {
     
      if (res.data) {
        // console.log(res.data.order_data);
        const { identity, order_status, product_id, platform_id } =
          res?.data?.order_data;
        let url;

        // if (order_status === 1) {
        //   url = `https://payments.hungama.com/payment_success?order_id=${orderid}`;
        // } else {
        //   url = `https://payments.hungama.com/payment_fail?identity=${identity}&order_id=${orderid}`;
        // }
        if (order_status === 1 && product_id === 1) {
          url = `/payment_success?identity=${identity}&order_id=${orderid}&lang=${lang}&product_id=${product_id}&platform_id=${platform_id}`;
        } else if (order_status !== 1 && product_id === 1) {
          url = `/payment_fail?identity=${identity}&order_id=${orderid}&lang=${lang}&product_id=${product_id}&platform_id=${platform_id}`;
        }

        if (order_status === 1 && product_id === 3) {
          url = `/pleng/payment_success?identity=${identity}&order_id=${orderid}&lang=${lang}&product_id=${product_id}&platform_id=${platform_id}`;
        } else if (order_status !== 1 && product_id === 3) {
          url = `/pleng/payment_fail?identity=${identity}&order_id=${orderid}&lang=${lang}&product_id=${product_id}&platform_id=${platform_id}`;
        }

        // console.log(url);
        window.location.href = url;
      } else {
        history.push("/404");
      }
    });
  }, [history, orderid]);
  return <></>;
}
