import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import { amplitudePageEvent } from '../../../utils';

import { PlengPaymentFailedCRBT } from "./paymentfailedCRBT";

export function PlengMobilePaymentFailedCRBT() {
  const [isLoading, setLoading] = useState(true);
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const lang = new URLSearchParams(location).get("lang");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const [orderData, setOrderData] = useState([]);
  const [txtData, setTextData] = useState({});
  const identity = new URLSearchParams(location).get("identity");
  const tone_id = new URLSearchParams(location).get("tone_id");
  const tone_title = new URLSearchParams(location).get("tone_title");
  const amount = new URLSearchParams(location).get("amount");
  const order_date = new URLSearchParams(location).get("order_date");
  const currency = new URLSearchParams(location).get("currency");
  const added_on = new URLSearchParams(location).get("added_on");
  const status = new URLSearchParams(location).get("status");
  // const callback_url = new URLSearchParams(location).get('callback_url');

  const formatAMPM = (date) => {
    // console.log(date.toUTCString(),'date');
    var dateString = date;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(1, 4).join(" ");
    var dateStringArr = dateString.split(" ");
    //console.log(dateStringArr)
    // console.log(dateStringArr[0]+', '+dateStringArr[1]+' '+dateStringArr[2]);
    var mdDate =
      dateStringArr[0] + ", " + dateStringArr[1] + " " + dateStringArr[2];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return mdDate + " | " + strTime;
  };

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };
      const langbody = {
        lang: lang,
        product_id:4
      };
      const body = {
        order_id: order_id
      };
      //lang
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${langbody.lang}&product_id=${langbody.product_id}`,
        headers: headers,
        data: langbody,
      })
        .then((res) => {
          if (res?.data) {
            setTextData(
              res?.data?.secondLang ? res?.data?.secondLang : res?.data?.default
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
            const orderData = {
              order_id : order_id,
              platform_id : platform_id,
              product_id: product_id,
              identity:identity,
              tone_id:tone_id,
              tone_title:tone_title,
              amount:amount,
              order_date:order_date,
              currency:currency,
              added_on:added_on,
              status:status
            }
            setOrderData(orderData);
    }
    setLoading(false);
  }, [isLoading, identity, order_id, lang, product_id]);
  console.log(isLoading,"MobilePaymentFailedCRBT orderData: ", orderData);
  return (
    <>
      {isLoading && <div className="loading">Loading...</div>}
      {!isLoading &&
        (orderData?.status === "Failed") && (
          <PlengPaymentFailedCRBT
            platform_id={platform_id}
            orderData={orderData}
            formatAMPM={formatAMPM}
            txtData={txtData}
            lang={lang}
          />
        )}
      {/* {!isLoading && (orderData.order_status==='2' || orderData.order_status===2) && <Paymentpending orderData={orderData} formatAMPM={formatAMPM}  />  } */}
      {/* <PlengPaymentFailed  platform_id={platform_id} orderData={orderData} formatAMPM={formatAMPM}  /> */}
    </>
  );
}
