/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const NonAppPurchasePixel = ({ orderData }) => {
  useEffect(() => {
    /* Meta Pixel Code */

    const newScript = document.createElement("script");
    newScript.setAttribute("type", "text/javascript");
    const inlineScript = document.createTextNode(`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '646273460308855');
    fbq('track', 'Purchase', {
      value: '${orderData?.txn_amount}',
      currency: '${orderData?.plan_currency}'
    });`);
    newScript.appendChild(inlineScript);
    document.head.appendChild(newScript);

    return () => {
      window.head.removeChild(newScript);
    };
  }, []);
  return <></>;
};
export default NonAppPurchasePixel;
