import amplitude from "amplitude-js";
import amplitudeKeys from "./../../constants/amplitudeProductKey.json";
export const initAmplitude = (productId = "1", platformId = "2") => {
  /* old logic 
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
  */

  amplitude
    .getInstance()
    .init(amplitudeKeys.product[productId].platform[platformId].AMPLITUDE_KEY);
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  console.log("userId-" + userId);
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  console.log(
    "eventType - " +
      eventType +
      " | eventProperties - " +
      JSON.stringify(eventProperties)
  );
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
