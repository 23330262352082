import React, { useEffect } from "react";
import Header from "../subpages/Header";
import Sidebar from "../subpages/Plans/Sidebar";
import Footer from "../subpages/Footer";
import { useLocation, useHistory } from "react-router-dom";
import { getParam } from "../../utils";
import axios from "axios";
import { plansReducer } from "../../utils/reducers";
import { defaultPlans } from "../../../constants";
import { Tvod } from "../subpages/success/tvod";
import { checkOrder } from "../../utils/payments";
import ReactGA from "react-ga";
import { Subscription } from "../subpages/success/subscription";
import { useImmerReducer } from "use-immer";
import { language } from "../../utils/services";

export function DesktopPaymentsuccess(props) {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);
  const location = useLocation().search;
  const order_id = getParam(location, "order_id");
  const lang = getParam(location, "lang");

  //const product_id = getParam(location, "product_id");
  const [plansData, setPlansData] = useImmerReducer(plansReducer, defaultPlans);
  console.log("plansData in success:", plansData);
  let history = useHistory();

  const updateMultipleFormData = (obj) => {
    setPlansData({ type: "updateMultipleFormData", payload: obj });
  };
  useEffect(() => {
    checkOrder(order_id).then((res) => {
      if (res.data) {
        console.log(res.data.order_data);
        const storedWebsite = localStorage.getItem("website");
        if(storedWebsite == 'true' || storedWebsite == true){
        sendEvent(res.data.order_data)
        }
        setPlansData({
          type: "updateMultipleFormData",
          payload: { orderData: res.data.order_data },
        });
      } else {
        history.push("/404");
      }
    });
    const sendEvent = async (order) => {
      const url = 'https://graph.facebook.com/v20.0/646273460308855/events';
      const response = await axios.get('https://api.ipify.org?format=json');

      const data = [
        {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          user_data: {
            em: [
              "309a0a5c3e211326ae75ca18196d301a9bdbd1a882a4d2569511033da23f0abd"
            ],
            ph: [
              "254aa248acb47dd654ca3ea53f48c2c26d641d23d7e2e93a1ec56258df7674c4",
              "6f4fcb9deaeadc8f9746ae76d97ce1239e98b404efe5da3ee0b7149740f89ad6"
            ],
            client_ip_address: response.data.ip,
            client_user_agent: navigator.userAgent,
            fbc: "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",
            fbp: "fb.1.1558571054389.1098115397"
          },
          custom_data: {
            order_data: order,
            value:order.txn_amount,
            currency:order.plan_currency
          },
          event_source_url: "https://payments.hungama.com",
          action_source: "website"
        }
      ];
  
      try {
        const res = await axios.post(url, {
          data: JSON.stringify(data),
          access_token: 'EAAUWW487JAABOzxXRMlbAaYjWaMDiDHC4wZATX0pwJZCZAOHu4LZAVx28Pq0fZBz8GZAXgvVSyZBainuVH8OwTqDW6QZBkv6jtlfIVMFedq7uEb88wu5cYn19GqN7ZBllZA4mF8mZBKm7x5mo8EjLVDOLQ9ZAD8fWonsm9a68ZB3nRZAkOozRwh5ZCSYQEFv3Mb6AlukuOlSQZDZD'
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('success response',res.data);
        // setResponse(res.data);
      } catch (error) {
        console.error("Error posting event:", error);
      }
    };
    //language
    language(lang).then((lan) => {
      //set default lang for lang convert
      //   setPlansData({
      //     type: "defaultlang",
      //     payload: lan?.default,
      //   });
      setPlansData({
        type: "language",
        payload: lan[lan?.secondLang ? "secondLang" : "default"],
      });
    });
  }, [history, setPlansData, order_id, lang]);
  return plansData.formData.orderData.plan_type !== "" ? (
    <div id="wrapper">
      <Header
        plansData={plansData}
        updateMultipleFormData={updateMultipleFormData}
      />
      <div id="content">
        <div className="main-contentBox-wrapper">
          <Sidebar plansData={plansData} />
          <div className="main-area-container remove-add-container">
            <div className="inner-mid-area">
              <div className="pdlr-40">
                <div className="breadcrumbs-nav flex1">
                  {/*        <div className="brdcrms-nav-left">
                                            <button className="btn-38"><a href={process.env.REACT_APP_HUNGAMA_WEB_URL}><span className="icon-Back"></span></a></button>
                                            <ul className="brdcms-ul">
                                                <li><a href="!#">Discover</a></li>
                                                <li><a href="!#">Buy Premium</a></li>
                                                <li><a href="!#">Gold Plan</a></li>
                                                <li><a href="!#">Payment Confirmation</a></li>
                                            </ul>
                                        </div>
                                        <div className="brdcrms-nav-right">

                                        </div>
        */}
                </div>
              </div>

              {plansData.formData.orderData.plan_type.toLowerCase() ===
                "tvod" ||
              plansData.formData.orderData.plan_type.toLowerCase() === "cvod" ||
              plansData.formData.orderData.plan_type.toLowerCase() ===
                "ptvod" ||
              plansData.formData.orderData.plan_type.toLowerCase() === "pvod" ||
              plansData.formData.orderData.plan_type
                .toLowerCase()
                .substring(0, 4) === "cvod" ||
              plansData.formData.orderData.plan_type
                .toLowerCase()
                .substring(0, 4) === "live_concert" ||
              plansData.formData.orderData.plan_type
                .toLowerCase()
                .substring(0, 5) === "ptvod" ||
              plansData.formData.orderData.plan_type
                .toLowerCase()
                .substring(0, 2) === "le" ? (
                <Tvod plansData={plansData} />
              ) : (
                (plansData.formData.orderData.plan_type.toLowerCase() ===
                  "event" ||
                  plansData.formData.orderData.plan_type.toLowerCase() ===
                    "subscription") && <Subscription plansData={plansData} />
              )}
              {/*
                                <div className="pay-offer-box pdlr-40">
                                    <p className="p-t1">Offers for you</p>
                                    <div className="hg-music-list-wrapper">
                                        <ul className="hg-music-list top-chart-ul">
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>
                                            <li className="hg_item">
                                                <div className="mb15">
                                                    <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                */}
            </div>
            <Footer lang={plansData?.language} />
          </div>
        </div>
      </div>
      {/* <!--Content Area End--> */}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
