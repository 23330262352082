import React from "react";
// import { Image } from "../../subcomponents/Elements";
export default function Sidebar({ plansData }) {
  return (
    <div className="left-side-bar">
      {/* <div id="left_side_tab" className={plansData.formData.sideBarMinimize ? "left-side-tab minimize-tab" : "left-side-tab"}>
            <div className="side-tab-btn">
                <button className="tablinks">
                    <span className="lft-tb-icon"><span className="icon-Discover"></span></span><span
                        className="side-opt-nm"><a href={process.env.REACT_APP_HUNGAMA_WEB_URL}>Discover</a></span>
                </button>
                <button className="tablinks">
                    <span className="lft-tb-icon"><span className="icon-Listen_unselect"></span></span><span
                        className="side-opt-nm"><a href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/music`}>Listen</a></span>
                </button>
                <button className="tablinks">
                    <span className="lft-tb-icon"><span className="icon-Watch_unselected"></span></span><span
                        className="side-opt-nm"><a href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/videos`}>Watch</a></span>
                </button>
                <button className="tablinks">
                    <span className="lft-tb-icon"><span className="icon-Library"></span></span><span
                        className="side-opt-nm"><a href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/library`}>Library</a></span>
                </button>
            </div>
            <div id="quick_access" className={plansData.formData.sideBarMinimize ? "quick-access quick-none" : "quick-access"}>
                <h3>Quick Access</h3>
                <div className="quick-access-item">
                    <ul className="quick-access-ul">
                        <li><a href="!#"><span><Image src="rctly-play.png" alt="rctly-play" /></span>
                            <span>Recently Played</span></a></li>
                        <li><a href="!#"><span><Image src="download.png" alt="download" /></span>
                            <span>Downloaded Songs</span></a></li>
                        <li><a href="!#"><span><Image src="playlist.png" alt="playlist" /></span>
                            <span>User Playlist 1</span></a></li>
                        <li><a href="!#"><span><Image src="more.png" alt="more" /></span>
                            <span>More</span></a></li>
                    </ul>
                </div>
            </div>

            
        </div>*/}
    </div>
  );
}
