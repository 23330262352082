/* eslint-disable */
import React from "react";
import { Image } from "../subcomponents/Elements";
export default function Footer({ lang }) {
  return (
    <footer id="footer">
      {/* <div className="footer-inner common-padding"> */}
      {/* <div className="description-box ">
                <h5>Description:</h5>
                <p>Hot Hindi Pop Songs, Hot Hindi Pop MP3 Songs, Best Hot Hindi Pop Songs, Top Hot Hindi Pop Songs,
                    Download Hot Hindi Pop Songs, Download Hot Hindi Pop MP3 Songs</p>
            </div> */}
      {/* <ul className="footer-link-wrapper">
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Latest Bollywood Songs</a></h4>
                        <a href="!#">Radhe - Your Most Wanted Bhai (Original Motion Picture Soundtrack)
                            Movie</a><span>|</span><a href="!#">All Bamb Movie</a><span>|</span><a
                                href="!#">Hooked Movie</a><span>|</span><a href="!#">Radhe - Your
                                    Most Wanted Bhai (Original Motion Picture Soundtrack) Movie</a><span>|</span><a
                                        href="!#">All Bamb Movie</a><span>|</span><a href="!#">Hooked
                                            Movie</a><span>|</span><a href="!#">Radhe - Your Most Wanted Bhai (Original Motion
                                                Picture Soundtrack) Movie</a><span>|</span><a href="!#">All Bamb
                                                    Movie</a><span>|</span><a href="!#">Hooked Movie</a><span>|</span><a
                                                        href="!#">Radhe - Your Most Wanted Bhai (Original Motion Picture Soundtrack)
                            Movie</a><span>|</span><a href="!#">All Bamb Movie</a><span>|</span><a
                                href="!#">Hooked Movie</a>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Top New Hindi Songs</a></h4>
                        <a href="!#">Bewafa Tera Masoom Chehra Song</a><span>|</span><a
                            href="!#">Lut Gaye (Feat. Emraan Hashmi) Song</a><span>|</span><a
                                href="!#">Panghat (From “Roohi”) Song</a><span>|</span><a
                                    href="!#">Jamaica to India Song</a><span>|</span><a
                                        href="!#">Baarish Ki Jaaye Song</a><span>|</span><a href="!#">Oh
                                            Sanam Song</a><span>|</span><a href="!#">Bewafa Tera Masoom Chehra
                                                Song</a><span>|</span><a href="!#">Lut Gaye (Feat. Emraan Hashmi)
                                                    Song</a><span>|</span><a href="!#">Panghat (From “Roohi”) Song</a><span>|</span><a
                                                        href="!#">Jamaica to India Song</a><span>|</span><a
                                                            href="!#">Baarish Ki Jaaye Song</a><span>|</span><a href="!#">Oh
                                                                Sanam Song</a><span>|</span>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Top Albums</a></h4>
                        <a href="!#">New Hindi Songs</a><span>|</span><a href="!#">New
                            International Songs</a><span>|</span><a href="!#">New Punjabi
                                Songs</a><span>|</span><a href="!#">New Retro Hindi Songs</a><span>|</span><a
                                    href="!#">New Tamil Songs</a><span>|</span><a href="!#">Oh Sanam
                                        Song</a><span>|</span><a href="!#">New Telugu Songs</a><span>|</span><a
                                            href="!#">New Devotional Songs</a><span>|</span><a href="!#">New
                                                Marathi Songs</a><span>|</span><a href="!#">New Bhojpuri Songs</a><span>|</span><a
                                                    href="!#">New Devotional Songs</a><span>|</span><a href="!#">New
                                                        Marathi Songs</a><span>|</span><a href="!#">New Kannada Songs</a><span>|</span><a
                                                            href="!#">New Malayalam Songs</a><span>|</span><a href="!#">New
                                                                Bhojpuri Songs</a><span>|</span><a href="!#">New Devotional
                                                                    Songs</a><span>|</span><a href="!#">New Marathi Songs</a><span>|</span><a
                                                                        href="!#">New Bhojpuri Songs</a><span>|</span>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Bollywood Top Artists</a></h4>
                        <a href="!#">New Hindi Songs</a><span>|</span><a href="!#">New
                            International Songs</a><span>|</span><a href="!#">New Punjabi
                                Songs</a><span>|</span><a href="!#">New Retro Hindi Songs</a><span>|</span><a
                                    href="!#">New Tamil Songs</a><span>|</span><a href="!#">Oh Sanam
                                        Song</a><span>|</span><a href="!#">New Telugu Songs</a><span>|</span><a
                                            href="!#">New Kannada Songs</a><span>|</span><a href="!#">New
                                                Malayalam Songs</a><span>|</span><a href="!#">New Bhojpuri
                                                    Songs</a><span>|</span><a href="!#">New Marathi Songs</a><span>|</span><a
                                                        href="!#">New Bhojpuri Songs</a><span>|</span><a href="!#">New
                                                            Devotional Songs</a><span>|</span><a href="!#">New Marathi
                                                                Songs</a><span>|</span><span>|</span><a href="!#">New Devotional
                                                                    Songs</a><span>|</span><a href="!#">New Marathi Songs</a><span>|</span><a
                                                                        href="!#">New Bhojpuri Songs</a><span>|</span><a href="!#">New
                                                                            Devotional Songs</a>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Bollywood Top Artists</a></h4>
                        <a href="!#">New Hindi Songs</a><span>|</span><a href="!#">New
                            International Songs</a><span>|</span><a href="!#">New Punjabi
                                Songs</a><span>|</span><a href="!#">New Retro Hindi Songs</a><span>|</span><a
                                    href="!#">New Tamil Songs</a><span>|</span><a href="!#">Oh Sanam
                                        Song</a><span>|</span><a href="!#">New Telugu Songs</a><span>|</span><a
                                            href="!#">New Kannada Songs</a><span>|</span><a href="!#">New
                                                Malayalam Songs</a><span>|</span><a href="!#">New Bhojpuri
                                                    Songs</a><span>|</span><a href="!#">New Devotional Songs</a><span>|</span><a
                                                        href="!#">New Marathi Songs</a><span>|</span><a href="!#">New
                                                            Bhojpuri Songs</a><span>|</span><a href="!#">New Devotional
                                                                Songs</a><span>|</span><a href="!#">New Marathi Songs</a><span>|</span><a
                                                                    href="!#">New Bhojpuri Songs</a><span>|</span><a href="!#">New
                                                                        Devotional Songs</a><span>|</span><a href="!#">New Marathi Songs</a><span>|</span>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Latest Bollywood Songs</a></h4>
                        <a href="!#">Radhe - Your Most Wanted Bhai (Original Motion Picture Soundtrack)
                            Movie</a><span>|</span><a href="!#">All Bamb Movie</a><span>|</span><a
                                href="!#">Hooked Movie</a><span>|</span><a href="!#">Radhe - Your
                                    Most Wanted Bhai (Original Motion Picture Soundtrack) Movie</a><span>|</span><a
                                        href="!#">All Bamb Movie</a><span>|</span><a href="!#">Hooked
                                            Movie</a><span>|</span><a href="!#">Radhe - Your Most Wanted Bhai (Original Motion
                                                Picture Soundtrack) Movie</a><span>|</span><a href="!#">All Bamb
                                                    Movie</a><span>|</span><a href="!#">Hooked Movie</a><span>|</span><a
                                                        href="!#">Radhe - Your Most Wanted Bhai (Original Motion Picture Soundtrack)
                            Movie</a><span>|</span><a href="!#">All Bamb Movie</a><span>|</span><a
                                href="!#">Hooked Movie</a>
                    </div>
                </li>
                <li>
                    <div className="footer-link-box">
                        <h4><a href="!#">Top New Hindi Songs</a></h4>
                        <a href="!#">Bewafa Tera Masoom Chehra Song</a><span>|</span><a
                            href="!#">Lut Gaye (Feat. Emraan Hashmi) Song</a><span>|</span><a
                                href="!#">Panghat (From “Roohi”) Song</a><span>|</span><a
                                    href="!#">Jamaica to India Song</a><span>|</span><a
                                        href="!#">Baarish Ki Jaaye Song</a><span>|</span><a href="!#">Oh
                                            Sanam Song</a><span>|</span><a href="!#">Bewafa Tera Masoom Chehra
                                                Song</a><span>|</span><a href="!#">Lut Gaye (Feat. Emraan Hashmi)
                                                    Song</a><span>|</span><a href="!#">Panghat (From “Roohi”) Song</a><span>|</span><a
                                                        href="!#">Jamaica to India Song</a><span>|</span><a
                                                            href="!#">Baarish Ki Jaaye Song</a><span>|</span><a href="!#">Oh
                                                                Sanam Song</a><span>|</span>
                    </div>
                </li>
            </ul> */}
      {/* </div> */}
      <div className="other-stats">
        <ul className="other-sts-ul common-padding">
          <li>
            <h4>
              <a href="#">{lang?.footer_apps}</a>
            </h4>
            <a href="#">{lang?.footer_mobile_tab}</a>
            <a href="#">{lang?.footer_drive_smart}</a>
            <a href="#">{lang?.footer_tv}</a>
          </li>
          <li>
            <h4>
              <a href="#">{lang?.footer_Rewards}</a>
            </h4>
            <a href="#">{lang?.footer_earn_coins_badge} </a>
            <a href="#">{lang?.footer_Winners_testimonials}</a>
          </li>
          <li>
            <h4>
              <a href="#">{lang?.footer_others}</a>
            </h4>
            <a href="#">{lang?.footer_help_faqs}</a>
            <a href="#">{lang?.footer_contactus}</a>
            <a href="#">{lang?.footer_sitemap}</a>
          </li>
          <li>
            <h4>
              <a href="#">{lang?.footer_company}</a>
            </h4>
            <a href="#">{lang?.footer_aboutus} </a>
            <a href="#">{lang?.footer_terms_cond}</a>
            <a href="#">{lang?.footer_privacy_policy}</a>
          </li>
          <li>
            <h4>
              <a href="#">{lang?.footer_partners}</a>
            </h4>
            <a href="#">{lang?.footer_bollywood_hungama}</a>
            <a href="#">{lang?.footer_artist_aloud}</a>
          </li>
        </ul>
      </div>
      <div className="ftr-social-link">
        <div className="made-in-india">
          <Image src="made-in-logo.png" alt="logo" />
        </div>
        <p>{lang?.footer_copyright}</p>
        <div className="social-links">
          <a href="#">
            <Image src="facebook.png" alt="facebook" />
          </a>
          <a href="#">
            <Image src="twiter.png" alt="facebook" />
          </a>
          <a href="#">
            <Image src="youtube.png" alt="facebook" />
          </a>
          <a href="#">
            <Image src="insta.png" alt="facebook" />
          </a>
        </div>
      </div>
    </footer>
  );
}
