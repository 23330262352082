import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PlengSubscriptionSuccess from "./pleng-subscriptionsuccessCRBT";
// import PlengTvodSuccess from "../transactionsuccess/pleng-tvodsuccess";
// import ReactGA from "react-ga";

export function PlengPaymentSuccessCRBT() {
  //  GA code
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname);
  const [isLoading, setLoading] = useState(true);
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const lang = new URLSearchParams(location).get("lang");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const identity = new URLSearchParams(location).get("identity");
  const tone_id = new URLSearchParams(location).get("tone_id");
  const tone_title = new URLSearchParams(location).get("tone_title");
  const amount = new URLSearchParams(location).get("amount");
  const order_date = new URLSearchParams(location).get("order_date");
  const currency = new URLSearchParams(location).get("currency");
  const added_on = new URLSearchParams(location).get("added_on");
  const status = new URLSearchParams(location).get("status");
  const [orderData, setOrderData] = useState([]);
  const [planType, setPlanType] = useState("");
  const [txtData, setTextData] = useState({});

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };
      const langbody = {
        lang: lang,
        product_id: 4,
      };
      const body = {
        order_id: order_id,
      };

      //lang
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${langbody.lang}&product_id=${langbody.product_id}`,
        headers: headers,
        data: langbody,
      })
        .then((res) => {
          if (res?.data) {
            setTextData(
              res?.data?.secondLang ? res?.data?.secondLang : res?.data?.default
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
        const orderData = {
          order_id : order_id,
          platform_id : platform_id,
          product_id: product_id,
          identity:identity,
          tone_id:tone_id,
          tone_title:tone_title,
          amount:amount,
          order_date:order_date,
          currency:currency,
          added_on:added_on,
          status:status
        }
        setOrderData(orderData);
      //check order

      // axios({
      //   method: "POST",
      //   url: `${process.env.REACT_APP_API_URL}/v1/user/check_order`,
      //   headers: headers,
      //   data: body,
      // })
      //   .then((res) => {
      //     if (res?.data) {
      //       setOrderData(res?.data?.data?.order_data);
      //       setPlanType(res?.data?.data?.order_data?.plan_type);
      //       setLoading(false);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
          setLoading(false);
      //   });
    }
  }, [isLoading, order_id, lang, product_id]);
  console.log('>>>>>>>>>>>>>>>>>>>>>>',orderData);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <PlengSubscriptionSuccess
          order_id={order_id}
          orderData={orderData}
          txtData={txtData}
          lang={lang}
        />
      )}
    </>
  );
}
