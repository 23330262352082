import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { logData } from "../../../utils/services";
import { ampRabbitMQPageEvent, getParam } from "../../../utils";
// import { initAmplitude, sendAmplitudeData, setAmplitudeUserId } from '../../../utils/amplitude';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function PlengSubscriptionSuccess({
  orderData,
  order_id,
  txtData,
  lang,
}) {
  //check and open success page in window.top.location
  if (window.top.location.href !== window.location.href) {
    window.top.location.href = window.location.href;
  }

  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  const isABAPopup = getParam(location, "cardparam");
  // const platform_id=new URLSearchParams(location).get('platform_id');
  const offer =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/offer.png";
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // const useAnalyticsEventTracker = (category="Payment Init") => {
  //     const eventTracker = (action = "action init", label = "label init") => {
  //       ReactGA.event({category, action, label});
  //     }
  //     return eventTracker;
  //   }

  //   const gaEventTracker = useAnalyticsEventTracker('Post Payment Screens');
  //   let history = useHistory();
  // const goToPreviousPath=()=>{
  //     // history.goBack()
  //     if(platform_id==='1'){
  //         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
  //     }
  // }
  const [isActive, setIsActive] = useState(false);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const identity = new URLSearchParams(location).get("identity");
  // const callback_url = new URLSearchParams(location).get('callback_url');
  let vouchers = [];

  let voucherImages = [offer, offer, offer, offer];
  for (let i = 0; i < voucherImages.length; i++) {
    vouchers.push(
      <SwiperSlide className="planSwiperSlider" key={i}>
        <li className="hg_item">
          <div className="mb15">
            <div className="hg_item_image_wrapper">
              {" "}
              <a href="#!">
                {" "}
                <img
                  className="hg_item_image"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/images/offer.png"
                  }
                  style={{ width: "120px" }}
                  alt="topchart1"
                />{" "}
              </a>{" "}
            </div>
          </div>
        </li>
      </SwiperSlide>
    );
  }
  var plan_currency_symbol = "";
  if (orderData?.plan_currency === "USD") {
    plan_currency_symbol = "$";
  } else {
    plan_currency_symbol = "₹";
  }

  var URL = "";
  // console.log("sdsdf", typeof orderData?.platform_id, orderData?.platform_id);
  if (orderData?.platform_id === 1 || orderData?.platform_id === 4) {
    URL = "http://hungama.com/payment/success/";
  }

  // console.log("URL:", URL);

  useEffect(() => {
    if (isLoadingOnce) {
      let source = "";
      if (
        orderData?.plan_type === "subscription" ||
        orderData?.plan_type === "event"
      ) {
        source = "PG Page_Subscription";
      }

      // amplitude with RabbitMQ
      ampRabbitMQPageEvent({
        identity: orderData?.identity,
        "Page Type": "PG Page_Success",
        Source: source,
        "Plan Name": orderData?.plan_name,
        "Plan Validity": orderData?.plan_valid,
        "Plan Value": orderData?.plan_amount,
        order_id: orderData?.order_id,
        "Page Language": lang ? lang : "en",
        product_id: orderData?.product_id,
        platform_id: orderData?.platform_id,
        device : "mobile"
      });
      /* Frontend Logs */
      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        order_data: orderData,
        log_time: new Date().toLocaleString().replace(",", ""),
        log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
      };
      logData({ logData: JSON.stringify(lgData) }).then(() => {});
    }
    setLoadingOnce(false);
  }, [
    order_id,
    orderData,
    isLoadingOnce,
    identity,
    couponcode,
    hostname,
    location,
    pathname,
    lang,
  ]);

  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          {/* <div className="pay-back">
            <img
              src={
                process.env.REACT_APP_ASSETS_URL +
                "/mobile/pleng/images/back-arrow.svg"
              }
              alt="arrow"
            />
          </div> */}
          <span>{txtData?.pay_confirmation_heading}</span>
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div className="ticket-card">
              <div
                className=""
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}+'/mobile/pleng/images/cover-img.png')`,
                }}
              ></div>
              <p>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/logo.svg"
                  }
                  alt="logo-confir-img"
                  className="logo-confir-img"
                />
              </p>
              <p>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/congratulations-icon.svg"
                  }
                  alt="confir-img"
                  className="confir-img"
                />
              </p>

              <h2 className="confir-title">
                {txtData?.pay_confirmation_title}
              </h2>
              <p className="confir-genre">
                {txtData?.pleng_confirmation_genre}
              </p>
            </div>

            <div className="pay-mode-box pay-mode-bottom-p">
              <p className="p-t1">{txtData?.pay_plan_details}</p>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.pay_subscription_plan}
                </div>
                <div className="order-last-col">Pleng Pro</div>
              </div>
              {orderData?.payment_id !== 14 && (
                <div className="order-detail">
                  <div className="order-first-col">{txtData?.amount}</div>
                  {/* <div className="order-last-col"><b>$5.99</b> Yearly</div> */}
                  <div className="order-last-col">
                    <b>
                      {plan_currency_symbol}
                      {orderData?.txn_amount}
                    </b>
                    {orderData?.plan_name}
                  </div>
                </div>
              )}
              {isActive && (
                <>
                  <div className="order-detail">
                    <div className="order-first-col">
                      {txtData?.subscription_valid_till}
                    </div>
                    <div className="order-last-col">{orderData?.end_date}</div>
                  </div>
                  <div className="order-detail">
                    <div className="order-first-col">{txtData?.order_id}</div>
                    <div className="order-last-col">{orderData?.order_id}</div>
                  </div>
                </>
              )}
              <div
                className="order-detail"
                onClick={() => setIsActive(!isActive)}
              >
                {/* <a className="view-more">View Less <img className="vm-down-arrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/down-arrow.svg"} alt="arrow"/></a> */}
                {/* <a href="javascript:;" class="view-more">View More <img class="vm-down-arrow" src="images/down-arrow.svg" alt="arrow"/></a> */}
                {isActive === true && (
                  <div className="view-more">
                    {txtData?.view_less}
                    <img
                      className="vm-down-arrow"
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/arrow-up.svg"
                      }
                      alt="arrowup"
                    />
                  </div>
                )}

                {isActive === false && (
                  <div className="view-more">
                    {txtData?.view_more}
                    <img
                      className="vm-down-arrow"
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/down-arrow.svg"
                      }
                      alt="arrowdown"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-action">
        <a href={URL} className="button-blue d-block">
          {txtData?.start_exploring}
        </a>
      </div>
    </div>
  );
}
