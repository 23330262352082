import { React, useState } from "react";
import { Image } from "../../subcomponents/Elements";
import { useTimer } from "react-timer-hook";
import {
  ampRabbitMQPopUpEvent,
  ampRabbitMQBtnEvent,
  checkUpiPayment,
} from "../../../utils";
import { SUCCESS } from "../../../../constants";
import { payUpi } from "../../../utils/payments";

export default function Popup({
  urlparams,
  updateMultipleFormData,
  paymentsData,
}) {
  // const [isRefDisabled,setIsRefDisabled]=useState(null);
  // const updateDisable=(bool)=>{
  //     setIsRefDisabled(bool)
  // }
  let data = {};
  const [isUpiBtnDisable, setUpiBtnDisable] = useState(false);
  // console.log('isUpiBtnDisable',isUpiBtnDisable)
  if (paymentsData.data?.upiPayments[0]) {
    const { plan_details_id, payment_id, upi_type } =
      paymentsData.data?.upiPayments[0];
    data.payment_id = payment_id;
    data.plan_details_id = plan_details_id;
    data.upi_type = upi_type;
  }

  const MyTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        // console.warn("onExpire called, redirect to pending");
        //  setIsRefDisabled(false)
        //  setTimeout(()=>{
        //  checkUpiPayment(false, data.payment_id, data.plan_details_id, paymentsData.formData.order_id, paymentsData)
        //  console.warn('onExpire checkUPIPayment Called!')
        //  },5000)

        //redirect to pending
        const { product_id, couponcode, identity, order_id } =
          paymentsData.formData;
        const { payment_id, plan_details_id } = data;
        const storedWebsite = localStorage.getItem("website");
        if(storedWebsite == 'true' || storedWebsite == true){
        window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=pending&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}&website=true`;
        }else{
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=pending&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}`;
        }
      },
    });
    return (
      <>
        <span style={{ fontSize: "1rem" }}>
          {minutes < 10 ? "0" + minutes : minutes}
        </span>
        :
        <span style={{ fontSize: "1rem" }}>
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      </>
    );
  };

  // commenting this function, because we are calling this in upi.js due to excluding verify popup : 03-08-23
  /*
  const verifyPayNow = () => {
    console.log(data);
    urlparams += `product_id=${paymentsData.formData.product_id}&type=upi_collect&vpa=${paymentsData.formData.upiId}`;

    // amplitudeBtnEvent(paymentsData.formData.identity, "Btn_Submit_UPI");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_Submit_UPI"
    );
    const { plan_name, plan_valid, plan_price, plan_currency } =
      paymentsData.data.productInfo;

    ampRabbitMQPopUpEvent({
      identity: paymentsData.formData.identity,
      Source: paymentsData.formData.utm_source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_price,
      "Plan Currency": plan_currency,
      "Plan ID": paymentsData.formData.plan_id,
      "Affiliate ID": paymentsData.formData.aff_id,
      product_id: paymentsData.formData.product_id,
      platform_id: paymentsData.formData.platform_id,
      Action: "UPI_Timer",
    });

    const upitype = data.upi_type;
    const pg = "UPI";
    const bankcode = "UPI";

    payUpi(
      upitype,
      data,
      pg,
      bankcode,
      urlparams,
      paymentsData.formData.couponcode
    ).then((res) => {
      console.log(res);
      const { status } = res.data;
      if (status === SUCCESS) {
        if (res.data.result.txnid !== " ") {
          setTimeout(() => {
            checkUpiPayment(
              false,
              data.payment_id,
              data.plan_details_id,
              res.data.result.txnid,
              paymentsData
            );
          }, 2000);
          updateMultipleFormData({
            payPopUp: true,
            isTimer: true,
            verifyPayPopUp: false,
            order_id: res.data.result.txnid,
          });
        }
      } else {
        // history.push("/404");
      }
    });
  };
  */

  const refreshPayNow = (e, data) => {
    e.stopPropagation();
    // amplitudeBtnEvent(
    //   paymentsData.formData.identity,
    //   "Btn_RefreshVerification_UPI"
    // );
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_RefreshVerification_UPI"
    );
    checkUpiPayment(
      true,
      data.payment_id,
      data.plan_details_id,
      paymentsData.formData.order_id,
      paymentsData
    );
    //do pay now with same upi id
    // verifyPayNow()
  };

  const cancelPayNow = (e, data) => {
    e?.stopPropagation();
    // amplitudeBtnEvent(
    //   paymentsData.formData.identity,
    //   "Btn_CloseVerification_UPI"
    // );
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_CloseVerification_UPI"
    );
    updateMultipleFormData({
      payPopUp: false,
      isTimer: false,
      verifyPayPopUp: false,
    });
    // const { product_id, couponcode, identity ,order_id} = paymentsData.formData;
    // const {payment_id,plan_details_id}=data;
    // console.log('cancel OOBJ:',product_id,couponcode,identity,order_id,data.payment_id,data.plan_details_id)
    // console.log('plan_details:',data.plan_details_id)
    // window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${ data.payment_id}&product_id=${product_id}&status=failure&order_id=${order_id}&plan_details_id=${ data.plan_details_id}&coupon_code=${couponcode}&identity=${identity}`;
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer
  //time.setSeconds(time.getSeconds() + 20);
  // console.log("TIME:", time);

  // useEffect(()=>{
  //     setIsRefDisabled(false)
  // },[])

  //call to upi timer function
  // if (paymentsData?.formData?.payPopUp) {
  //   verifyPayNow();
  // }

  return (
    <>
      <div
        className="pay-popup-wrap"
        id="phonepe-pop"
        style={{
          display: paymentsData?.formData?.reedemCoinsFailed ? "block" : "none",
        }}
      >
        <div className="paypopup-box">
          <span
            onClick={(e) =>
              updateMultipleFormData({
                reedemCoinsFailed: !paymentsData?.formData?.reedemCoinsFailed,
              })
            }
            className="pop-close"
            id="pop-closebtn"
          >
            <Image src="close-btn.png" alt="" />
          </span>
          <div className="paypop-content">
            <div className="pop-pay-icon-box">
              <p className="pop-secure">
                {paymentsData?.language?.pay_popup_coin_insufficient}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- verify popupbox start--> */}
      {/* <div
        className="pay-popup-wrap"
        id="verify-pop"
        style={{
          display: paymentsData?.formData?.verifyPayPopUp ? "block" : "none",
        }}
      >
        <div className="paypopup-box">
          <span
            onClick={(e) =>
              updateMultipleFormData({
                verifyPayPopUp: !paymentsData?.formData?.verifyPayPopUp,
              })
            }
            className="pop-close"
            id="pop-closebtn"
          >
            <Image src="close-btn.png" alt="" />
          </span>
          <div className="paypop-content">
            <div className="pop-pay-icon-box">
              <img
                src={paymentsData?.formData?.upiType}
                className="pop-payicon"
                alt=""
              />
              <p className="pop-pay-txt">
                {paymentsData?.language?.pay_poup_pay_with}
              </p>
              <h4 className="pop-upi-code">{paymentsData?.formData?.upiId}</h4>
              <span
                style={{
                  pointerEvents: isUpiBtnDisable === true ? "none" : "auto",
                }}
                onClick={(e) => {
                  verifyPayNow();
                  setUpiBtnDisable(true);
                }}
                className="pop-btn"
              >
                {paymentsData?.language?.pay_paynow}
              </span>
              <p className="pop-secure">
                <Image src="lock-icon.svg" className="lock-icon" alt="" />
                {paymentsData?.language?.pay_secure_text}
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- verify popupbox end-->

<!-- timer popupbox start--> */}
      <div
        className="pay-popup-wrap"
        id="phonepe-pop"
        style={{ display: paymentsData?.formData?.payPopUp ? "block" : "none" }}
      >
        <div className="paypopup-box">
          <span
            onClick={(e) => {
              updateMultipleFormData({
                payPopUp: !paymentsData?.formData?.payPopUp,
              });
            }}
            className="pop-close"
            id="pop-closebtn"
          >
            <Image src="close-btn.png" alt="" />
          </span>
          <div className="paypop-content">
            <div className="pop-pay-icon-box">
              <img
                src={paymentsData?.formData?.upiType}
                className="pop-payicon"
                alt=""
              />
              <p className="pop-pay-txt" style={{ fontSize: "1rem" }}>
                {`${paymentsData?.language?.pay_open} ${paymentsData?.formData?.upiName}`}
              </p>
              <p className="pop-pay-txt1">
                {/* Please wait. Verifying payment Status */}
                {paymentsData?.language?.pay_popup_please_wait}
              </p>
              <div className="pop-pay-timmer">
                {paymentsData?.formData?.isTimer && (
                  <MyTimer expiryTimestamp={time} />
                )}
              </div>
              <div className="pop-pay-btnbox">
                <span
                  style={{
                    margin: "0 1%",
                    width: "44%",
                    borderRadius: "30px",
                  }}
                  onClick={(e) => {
                    cancelPayNow(e, data);
                    setUpiBtnDisable(false);
                  }}
                  className="pop-grey-btn"
                >
                  {paymentsData?.language?.pay_popup_cancel}
                </span>
                <span
                  style={{
                    margin: "0 1%",
                    width: "44%",
                    borderRadius: "30px",
                  }}
                  onClick={(e) => {
                    // if(!isRefDisabled){
                    //     refreshPayNow(e,data)
                    // }
                    // else{
                    //     console.log('BTN Disabled!')
                    // }
                    refreshPayNow(e, data);
                  }}
                  className="pop-btn"
                >
                  {paymentsData?.language?.pay_popup_refresh}
                </span>
              </div>
              <p className="pop-secure" style={{ fontSize: "1vm" }}>
                <Image src="lock-icon.svg" className="lock-icon" alt="" />
                {paymentsData?.language?.pay_secure_text}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- timer popupbox end--> */}
    </>
  );
}
