import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PlengSubscriptionSuccess from "../transactionsuccess/pleng-subscriptionsuccess";
import PlengTvodSuccess from "../transactionsuccess/pleng-tvodsuccess";
// import ReactGA from "react-ga";

export function PlengPaymentSuccess() {
  //  GA code
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname);
  const [isLoading, setLoading] = useState(true);
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const lang = new URLSearchParams(location).get("lang");
  const [orderData, setOrderData] = useState([]);
  const [planType, setPlanType] = useState("");
  const [txtData, setTextData] = useState({});

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };
      const langbody = {
        lang: lang,
        product_id: product_id,
      };
      const body = {
        order_id: order_id,
      };

      //lang
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${langbody.lang}&product_id=${langbody.product_id}`,
        headers: headers,
        data: langbody,
      })
        .then((res) => {
          if (res?.data) {
            setTextData(
              res?.data?.secondLang ? res?.data?.secondLang : res?.data?.default
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //check order

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/user/check_order`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          if (res?.data) {
            setOrderData(res?.data?.data?.order_data);
            setPlanType(res?.data?.data?.order_data?.plan_type);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [isLoading, order_id, lang, product_id]);
  console.log(orderData);
  console.log(planType);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : planType?.toLowerCase()?.substring(0, 4) === "live_concert" ||
        planType?.toLowerCase()?.substring(0, 2).toLowerCase() === "le" ? (
        <PlengTvodSuccess
          order_id={order_id}
          orderData={orderData}
          txtData={txtData}
          lang={lang}
        />
      ) : (
        <PlengSubscriptionSuccess
          order_id={order_id}
          orderData={orderData}
          txtData={txtData}
          lang={lang}
        />
      )}
    </>
  );
}
