/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import ReactGA4 from "react-ga4";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash.get";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPopUpEvent,
  ampRabbitMQPageEvent,
  generateAuthKey,
} from "../../utils";
import {
  language,
  updateOrderStatus,
  checkFreeTrialStatus,
  HMCheckUserExistsOrSingUp,
} from "../../utils/services";
import { freeTrialPlanData, SUCCESS } from "../../../constants";

import CCDC from "../nonapppaymentcomponents/ccdc";
import UpiPayments from "../nonapppaymentcomponents/upi";
import NonAppPageViewPixel from "../nonapppaymentcomponents/NonAppPageViewPixel";

export const NonAppPayment = (props) => {
  /* Hooks */
  const ref = useRef();
  const history = useHistory();
  let currenturl = useLocation().search;
  const pathname = useLocation().pathname;
  const location = useLocation().search;
  const hostname = window.location.host;

  /* Params */
  // const identity = new URLSearchParams(location).get("identity");
  const non_app_source = new URLSearchParams(location).get("non_app_source");
  const couponcode = new URLSearchParams(location).get("couponcode")
    ? new URLSearchParams(location).get("couponcode")
    : "";
  // const auth = new URLSearchParams(location).get("auth");
  let plan_id = new URLSearchParams(location).get("plan_id")
    ? new URLSearchParams(location).get("plan_id")
    : "10";
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const country = new URLSearchParams(location).get("country");
  const plan_type = new URLSearchParams(location).get("plan_type");
  const content_id = new URLSearchParams(location).get("content_id");
  let upilist = "Paytm,PhonePe,GPay,Bhim";
  const uL = new URLSearchParams(location).get("upilist")
    ? new URLSearchParams(location).get("upilist")
    : upilist;

  // const appVersion = new URLSearchParams(location).get("app_version");
  // const buildNumber = new URLSearchParams(location).get("build_number");
  const lan = new URLSearchParams(location).get("lang");
  // const npay_redirect = new URLSearchParams(location).get("npay_redirect");
  // const npay_campaignsource = new URLSearchParams(location).get(
  //   "npay_campaignsource"
  // );
  // const campaignsource = new URLSearchParams(location).get("campaignsource");
  // const npay_affilaite = new URLSearchParams(location).get("npay_affilaite");
  // const aff_id = new URLSearchParams(location).get("aff_id");
  const utm_source = new URLSearchParams(location).get("utm_source")
    ? new URLSearchParams(location).get("utm_source")
    : "";
  const utm_campaign = new URLSearchParams(location).get("utm_campaign")
    ? new URLSearchParams(location).get("utm_campaign")
    : "";
  // const originalSource = new URLSearchParams(location).get("source");
  let urlparams = location.replace("?", "");

  /* States */
  const [validUser, setValidUser] = useState({
    isGold: false,
    isValid: true,
    identity: "",
    auth: "",
    mobileno: non_app_source ? non_app_source : "",
    nonAppSource: false,
  });
  const [plan, setPlan] = useState({ discountPrice: " " });
  const [plans, setPlans] = useState([]);
  const [planArr, setPlanArr] = useState([]);
  const [featured, setFeatured] = useState({});
  const [payments, setPayments] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [total_user_coins, setTotal_user_coins] = useState([]);
  const [netBankingPayments, setNetBankingPayments] = useState([]);
  const [upiPayments, setUpiPayments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingPayApi, setLoadingPayAPi] = useState(false);
  const [isLoadingVerify, setisLoadingVerify] = useState(false);
  // const [defaultTextData, setDefaultTextData] = useState({});
  const [textData, setTextData] = useState({});
  const [pushbar, setPushbar] = useState({
    openCoupon: false,
    Verifybottom1: false,
    Verifybottom2: false,
    verifyRedeemCoins: false,
    verifyAmazonPay: false,
    isTimer: false,
  });
  const [formData, setFormData] = useState({
    couponcode: couponcode,
    couponVerified: false,
    couponError: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
    plan_id: plan_id,
  });
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [tabHideShow, setTabHideShow] = useState({
    upiTab: true,
    ccTab: false,
  });
  const [freeTrialData, setFreeTrialData] = useState({});
  const [freeCouponData, setFreeCouponData] = useState({});
  const [isFreeCoupon, setIsFreeCoupon] = useState(false);
  /* set sessionStorage for user retry with upilist */

  // sessionStorage.setItem(
  //   "userRetryInfo",
  //   JSON.stringify({
  //     identity: validUser?.identity,
  //     upilist: uL,
  //   })
  // );

  /* Functions */

  const goToPreviousPath = (contentId, planName) => {
    ampRabbitMQBtnEvent(
      {
        identity: validUser?.identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Back_PG"
    );

    history.goBack();
  };

  //handleMobileInput onchange
  const handleMobileInput = async (e) => {
    const { name, value } = e?.target;
    // console.log(name, value);
    if (value?.length > 0 && value?.length !== 10) {
      setValidUser((prev) => {
        return { ...prev, isValid: false, identity: "" };
      });
    } else if (value?.length === 10) {
      //call api
      let result = await HMCheckUserExistsOrSingUp(`91${value}`.trim());

      // console.log("res", result);
      if (result?.data?.identity && result?.data?.subscription_status === 1) {
        setValidUser((prev) => {
          return {
            ...prev,
            isValid: true,
            isGold: true,
            identity: result?.data?.identity,
            auth: generateAuthKey(result?.data?.identity),
            mobileno: value,
          };
        });
      } else if (
        result?.data?.identity &&
        result?.data?.subscription_status === 0
      ) {
        setValidUser((prev) => {
          return {
            ...prev,
            isValid: true,
            isGold: false,
            identity: result?.data?.identity,
            auth: generateAuthKey(result?.data?.identity),
            mobileno: value,
          };
        });
      }
    } else {
      setValidUser((prev) => {
        return {
          ...prev,
          isValid: true,
          isGold: false,
          identity: "",
          auth: "",
          mobileno: "",
        };
      });
    }
  };

  const changePlanTick = (e, id) => {
    const checkList = document?.querySelectorAll(".checkmark");
    // Remove "checked" class from all elements with the class "checkmark"
    checkList?.forEach((element) => {
      element.classList.remove("checked");
    });

    // Add "checked" class to the specific element with the ID and class "checkmark"
    const targetElement = e?.currentTarget;
    // console.log("targetElement:", targetElement);
    if (targetElement) {
      const spanChild = targetElement.querySelector(".checkmark");
      if (spanChild) {
        spanChild.classList.add("checked");
      }
    }
  };
  const handlePlanCheck = (e, pdata) => {
    //console.log(value)
    let data = { ...formData, plan_id: pdata?.plan_id };
    data[name] = pdata?.plan_name;
    setFormData(data);
    changePlanTick(e, pdata?.plan_name);
    setLoadingPayAPi(true);
  };

  useEffect(() => {
    if (non_app_source !== "") {
      let e = { target: { name: "mobileno", value: non_app_source } };
      // console.log("auth api call");
      handleMobileInput(e);
    }
  }, []);

  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({
        product_id,
        country,
        identity: validUser?.identity,
      }).then((res) => {
        if (res?.status === SUCCESS) {
          // console.log("freetrial success!", res);
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
          } else {
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, validUser?.identity, product_id]);

  useEffect(() => {
    if (isLoading && Object.keys(freeTrialData)?.length > 0) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        plan_id: formData?.plan_id,
        plan_type: plan_type,
        content_id: content_id,
        identity: validUser?.identity,
      };

      //plan API
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/plans?product_id=${body.product_id}&platform_id=${body.platform_id}&country=${body.country}&identity=${body.identity}`,
        headers: headers
        // data: body,
      })
        .then((res) => {
          //Free Trial start
          if (freeTrialData?.status === false) {
            //free trial plan amt
            const rowToChange = res?.data?.plans?.findIndex((item) => {
              return item.plan_id === Number(freeTrialData?.plan_id);
            });
            res.data.plans[rowToChange].plan_price = freeTrialData?.plan_amt;
            res.data.plans[rowToChange].is_recommended = true;
            res.data.plans[rowToChange].freeTrialPlanRecText =
              freeTrialData?.freeTrialPlanRecText;
          }
          //Free Trial end
          const plansdata = res?.data?.plans;
          // const sliderInfodata = res.data.sliderInfo;
          let recommendedplan_id;
          plansdata.forEach((x, i) => {
            if (x.is_recommended) {
              recommendedplan_id = x.plan_id;
            }
          });

          setFormData((prev) => {
            return {
              ...prev,
              plan_id: recommendedplan_id,
            };
          });

          // setSlider(sliderInfodata);
          setPlans(plansdata);
          //set planArr for Plans UI
          let arr = [];
          for (let df = 0; df < plansdata?.length; df++) {
            //free trial plan amt change

            const data = plansdata[df];
            if (df % 2) {
              continue;
            }
            let data1 = "";
            if (df + 1 !== "") {
              data1 = plansdata[df + 1];
            }

            arr.push({ data: data, data1: data1 });
          }
          setPlanArr(arr);
          // setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          // setLoading(false);
        });

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          //Free Trial Payment Page Code
          if (
            Number(freeTrialData?.plan_id) === Number(plan_id) &&
            freeTrialData?.status === false
          ) {
            //  console.log('freetrial set:::',obj)
            // console.log("freetrial success payment page mob");
            res.data.planInfo.plan_price = Number(freeTrialData?.plan_amt);
            res.data.payments = res?.data?.payments.filter(
              (el) =>
                el?.plan_group_type === "CC" ||
                el?.plan_group_type === "upiPayments"
            );
            // console.log(
            //   "updated payments::",
            //   res?.data?.payments,
            //   "arr",
            //   res?.data?.payments.filter(
            //     (el) =>
            //       el?.plan_group_type === "CC" ||
            //       el?.plan_group_type === "upiPayments"
            //   )
            // );
            //remive coupon on trial offer
            setFormData((prev) => ({
              couponcode: "",
            }));
            //set default open UPI panel
            setTabHideShow((prevState) => ({
              ...prevState,
              upiTab: true,
              ccTab: false,
            }));
          }
          const featuredData = res?.data?.featuredPayment;
          const planData = res?.data?.planInfo;
          const paymentsdata = res?.data?.payments;
          const walletPaymentsdata = res?.data?.walletPayments;
          const upiPaymentsdata = res?.data?.upiPayments;
          const netBankingPaymentsdata = res?.data?.netBankingPayments;
          // console.log(paymentsdata);
          // console.log("payments.total_user_coins");
          // console.log();
          //var item_value = sessionStorage.getItem("plan_name_val");

          // Assign value to a key
          sessionStorage.setItem(
            "plan_name_val",
            planData?.plan_name + "_" + planData?.plan_price
          );
          setTotal_user_coins(res?.data?.total_user_coins);
          setFeatured(featuredData);
          setPlan(planData);
          setPayments(paymentsdata);
          setWallets(walletPaymentsdata);
          setNetBankingPayments(netBankingPaymentsdata);
          setUpiPayments(upiPaymentsdata);
          setLoading(false);
          setisLoadingVerify(true);

          const logData = {
            url: `${hostname}${pathname}`,
            params: location,
            couponcode: couponcode,
            plan_id: plan_id,
            utm_source: utm_source ? utm_source : "",
            utm_campaign: utm_campaign ? utm_campaign : "",
            product_id: product_id,
            platform_id: platform_id,
            country: country,
            identity: validUser?.identity,
            content_id: content_id,
            plan_type: plan_type,
            upiList: uL,
          };

          const data = JSON.stringify(logData);
          const body1 = {
            logData: data,
          };

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
            headers: headers,
            data: body1,
          })
            .then((res) => {
              language(lan)
                .then((res) => {
                  //set conditionally
                  setTextData(
                    res["secondLang"] ? res["secondLang"] : res["default"]
                  );

                  //call event api

                  //amplitude code with RabbitMQ

                  ampRabbitMQPageEvent({
                    identity: validUser?.identity ? validUser?.identity : "",
                    "Page Type": "Non_app_subs",
                    "Plan Name": planData?.plan_name,
                    "Plan Validity": planData?.plan_valid,
                    "Plan Value": planData?.plan_price?.toFixed(2),
                    "Plan Currency": planData?.plan_currency,
                    "Plan ID": plan_id ? plan_id : "",
                    "Page Language": lan ? lan : "en",
                    product_id: product_id ? product_id : "",
                    platform_id: platform_id ? platform_id : "",
                    "Npay Campaign Name" : utm_campaign ? utm_campaign :"",
                    device : "mobile"
                  });

                  //GA4 Event
                  ReactGA4.event({
                    category: "UTM Source",
                    action: utm_source,
                    label: utm_source, // optional
                  });
                  ReactGA4.event({
                    category: "UTM Campaign",
                    action: utm_campaign,
                    label: utm_campaign, // optional
                  });
                })
                .catch((err) => {
                  console.log("lang  api err", err);
                });
            })
            .catch((err) => {
              console.log("frontend log api err", err);
            });
        })
        .catch((err) => {
          console.log("payment api err:", err);
          setLoading(false);
        });
    }

    return () => {
      setLoading(false);
    };
  }, [
    utm_source,
    utm_campaign,
    pushbar.openCoupon,
    country,
    couponcode,
    hostname,
    validUser,
    location,
    pathname,
    uL,
    isLoading,
    plan_id,
    urlparams,
    content_id,
    plan_type,
    platform_id,
    product_id,
    lan,
    freeTrialData,
    isFreeCoupon,
  ]);

  // useEffect for plan change
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": process.env.REACT_APP_API_KEY,
    };

    const body = {
      product_id: product_id,
      platform_id: platform_id,
      country: country,
      plan_id: formData?.plan_id,
      plan_type: plan_type,
      content_id: content_id,
      identity: validUser?.identity,
    };
    if (isLoadingPayApi) {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          //Free Trial Payment Page Code
          if (
            Number(freeTrialData?.plan_id) === Number(plan_id) &&
            freeTrialData?.status === false
          ) {
            //  console.log('freetrial set:::',obj)
            // console.log("freetrial success payment page mob");
            res.data.planInfo.plan_price = Number(freeTrialData?.plan_amt);
            res.data.payments = res?.data?.payments.filter(
              (el) =>
                el?.plan_group_type === "CC" ||
                el?.plan_group_type === "upiPayments"
            );
            // console.log(
            //   "updated payments::",
            //   res?.data?.payments,
            //   "arr",
            //   res?.data?.payments.filter(
            //     (el) =>
            //       el?.plan_group_type === "CC" ||
            //       el?.plan_group_type === "upiPayments"
            //   )
            // );
            //remive coupon on trial offer
            setFormData((prev) => ({
              couponcode: "",
            }));
            //set default open UPI panel
            setTabHideShow((prevState) => ({
              ...prevState,
              upiTab: true,
              ccTab: false,
            }));
          }
          const featuredData = res?.data?.featuredPayment;
          const planData = res?.data?.planInfo;
          const paymentsdata = res?.data?.payments;
          const walletPaymentsdata = res?.data?.walletPayments;
          const upiPaymentsdata = res?.data?.upiPayments;
          const netBankingPaymentsdata = res?.data?.netBankingPayments;
          // console.log(paymentsdata);
          // console.log("payments.total_user_coins");
          // console.log();
          //var item_value = sessionStorage.getItem("plan_name_val");

          // Assign value to a key
          sessionStorage.setItem(
            "plan_name_val",
            planData?.plan_name + "_" + planData?.plan_price
          );
          setTotal_user_coins(res?.data?.total_user_coins);
          setFeatured(featuredData);
          setPlan(planData);
          setPayments(paymentsdata);
          setWallets(walletPaymentsdata);
          setNetBankingPayments(netBankingPaymentsdata);
          setUpiPayments(upiPaymentsdata);
          setLoadingPayAPi(false);
        })
        .catch((err) => {
          console.log("payment api on plan change err:", err);
        });
    }

    return () => {
      setLoadingPayAPi(false);
    };
  }, [formData?.plan_id]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer

  // console.log(upiPayments);
  useEffect(() => {
    if (
      pushbar.openCoupon ||
      pushbar.Verifybottom1 ||
      pushbar.Verifybottom2 ||
      pushbar.verifyRedeemCoins ||
      pushbar.verifyAmazonPay
    ) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!pushbar.openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [pushbar]);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <div>
          <div className="header-sec">
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/sss_1280x719.jpg`}
              id="header-img"
              alt="header"
            />
            {/* HLS video  Start*/}
            {/* <video
              id="header-video"
              className="hide"
              playsinline
              muted
              loop
              poster={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/header-img.jpg`}
            >
              <source
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/bg-video-webm.webm`}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video> */}

            {/* <video
              id="header-video"
              className="hide"
              playsInline
              muted
              loop
              poster={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/sss_1280x719.jpg`}
              alt="header"
            ></video> */}
            {/* HLS video end */}
            <div className="log-sec">
              <p>You'll get even more with</p>
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/hungama-logo.svg`}
                alt="logo"
              />
            </div>
          </div>
          <div className="features-sec">
            <div className="fetaure-wrapper">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/ads-icon.svg`}
                alt="adsicon"
              />
              <p>
                Ads Free
                <br />
                Music
              </p>
            </div>
            <div className="fetaure-wrapper movie">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/movies-icon.svg`}
                alt="moviesicon"
              />
              <p>Movies, TV Shows & Originals</p>
            </div>
            <div className="fetaure-wrapper download">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/download-icon.svg`}
                alt="downloadicon"
              />
              <p>Unlimited Downloads</p>
            </div>
            <div className="fetaure-wrapper quality">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/quality-icon.svg`}
                alt="qualityicon"
              />
              <p>Better Quality Music</p>
            </div>
          </div>

          {Object.keys(plan).length > 0 ? (
            <div className="main-sec">
              <div className="plan-sec">
                <p className="title">Limited Period Offer</p>
                {/* old non app plan info */}
                {/* <div className="plan-details">
                  <div className="category-sec">
                    <p className="plan-type">
                      {plan?.plan_name ? plan?.plan_name : ""}
                    </p>
                    <p className="per-price">
                      

                      {["8", "10", "38"].includes(plan_id) &&
                      plan?.original_price > plan?.plan_price &&
                      plan?.plan_currency_symbol
                        ? `${plan?.plan_currency_symbol}${(
                            plan?.plan_price / 12
                          ).toFixed(2)}/month`
                        : ""}
                    </p>
                  </div>
                  <div className="price-sec">
                    <p className="plan-price">
                      {plan?.original_price > plan?.plan_price ? (
                        <span className="main-price">
                          {plan?.plan_currency_symbol
                            ? plan?.plan_currency_symbol
                            : ""}
                          {plan?.original_price}
                        </span>
                      ) : (
                        ""
                      )}

                      
                      {plan?.plan_currency_symbol
                        ? plan?.plan_currency_symbol
                        : ""}
                      <span className="fw-700">
                        {plan?.plan_price ? plan?.plan_price : ""}
                      </span>
                    </p>
                    {plan?.original_price > plan?.plan_price ? (
                      <p className="price-percent">{`Save ${Math.round(
                        (parseInt(plan?.plan_price) /
                          parseInt(plan?.original_price)) *
                          100
                      )}%`}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}

                <div className="plan-details plan-row">
                  {planArr?.map((el, index) => {
                    return (
                      <>
                        <div
                          className="plan-col mb-1"
                          id={el?.data?.plan_name}
                          onClick={(e) => handlePlanCheck(e, el?.data)}
                        >
                          {el?.data?.is_recommended === true &&
                            el?.data?.plan_id === 10 && (
                              <span className="plan-tag">
                                <img
                                 style={{"position": "absolute",
                                  "top": "0px",
                                  "left": "10px",
                                  "border-radius": "5px",
                                  "width": "120px",}}
                                  // src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/holi_tag.svg`}
                                  src={"https://images.hungama.com/c/uploads_hungama/20240910/20240910062219_festive_offer.png"}
                                  alt=""
                                />
                              </span>
                            )}
                          <p className="plan-type">{el?.data?.plan_name}</p>
                          <p className="plan-price">
                            <img
                              src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/rupee-icon.svg`}
                              alt=""
                            />
                            {el?.data?.plan_price}
                          </p>
                          <span
                            className={`checkmark ${
                              el?.data?.is_recommended ? "checked" : ""
                            }`}
                          ></span>
                        </div>
                        {el?.data1 && (
                          <div
                            className="plan-col mb-1"
                            id={el?.data1?.plan_name}
                            onClick={(e) => handlePlanCheck(e, el?.data1)}
                          >
                            {el?.data?.is_recommended === true &&
                              el?.data1?.plan_id === 10 && (
                                <span className="plan-tag">
                                  <img
                                   style={{"position": "absolute",
                                    "top": "0px",
                                    "left": "10px",
                                    "border-radius": "5px",
                                    "width": "120px",}}
                                    // src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/holi_tag.svg`}
                                    src={"https://images.hungama.com/c/uploads_hungama/20240910/20240910062219_festive_offer.png"}
                                    alt=""
                                  />
                                </span>
                              )}
                            <p className="plan-type">{el?.data1?.plan_name}</p>
                            <p className="plan-price">
                              <img
                                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/rupee-icon.svg`}
                                alt=""
                              />
                              {el?.data1?.plan_price}
                            </p>
                            <span
                              className={`checkmark ${
                                el?.data1?.is_recommended ? "checked" : ""
                              }`}
                            ></span>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className="form-sec">
                  <p>Enter your mobile number to avail this offer*</p>
                  <div className="input-rowbox">
                    <span>
                      <img
                        src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/in.webp`}
                        alt="inwebp"
                      />
                      (+91)
                    </span>
                    <input
                      type="text"
                      name="mobileno"
                      className="mobileno"
                      placeholder="Enter your 10 digit mobile number"
                      maxLength={10}
                      defaultValue={validUser?.mobileno}
                      onChange={handleMobileInput}
                      onLoad={non_app_source ? handleMobileInput : ""}
                      readOnly={pushbar?.Verifybottom2 ? true : false}
                      autoComplete="off"
                    />
                  </div>
                  {validUser?.isGold && (
                    <p className="gold-msg">You are an Existing Gold Member</p>
                  )}
                  {validUser?.isValid === false && (
                    <p className="error-msg">Please enter your mobile number</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  pointerEvents:
                    pushbar?.openCoupon ||
                    pushbar?.Verifybottom1 ||
                    pushbar?.Verifybottom2 ||
                    pushbar?.verifyRedeemCoins ||
                    pushbar?.verifyAmazonPay ||
                    validUser?.isGold === true ||
                    validUser?.identity === ""
                      ? "none"
                      : "auto",
                }}
              >
                <p className="payment-text">Select preferred payment mode*</p>
                {payments
                  .sort(
                    (a, b) =>
                      parseFloat(a.display_order) - parseFloat(b.display_order)
                  )
                  .map((data, i) => (
                    <div key={i}>
                      {data.plan_group_type === "upiPayments" && (
                        <UpiPayments
                          tabHideShow={tabHideShow}
                          setTabHideShow={setTabHideShow}
                          pushbar={pushbar}
                          setPushbar={setPushbar}
                          plan_id={plan_id}
                          planData={plan}
                          uL={uL}
                          identity={validUser?.identity}
                          validUser={validUser}
                          product_id={product_id}
                          platform_id={platform_id}
                          upiPayments={upiPayments}
                          urlparams={urlparams}
                          textData={textData}
                          coupon_code={isCouponValid ? formData.couponcode : ""}
                          isFreeTrial={freeTrialData}
                        />
                      )}

                      {data.plan_group_type === "CC" && (
                        <CCDC
                          tabHideShow={tabHideShow}
                          setTabHideShow={setTabHideShow}
                          pushbar={pushbar}
                          setPushbar={setPushbar}
                          plan_id={plan_id}
                          planData={plan}
                          identity={validUser?.identity}
                          validUser={validUser}
                          product_id={product_id}
                          platform_id={platform_id}
                          payments={payments}
                          urlparams={urlparams}
                          textData={textData}
                          total_user_coins={total_user_coins}
                          coupon_code={isCouponValid ? formData.couponcode : ""}
                          isFreeTrial={freeTrialData}
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="buy-btn-sec">
            <p>
              By clicking you agree to our
              <a href={`https://hungama.com/conditions`}>{" Terms of Use "}</a>
              and acknowledge that
              <br />
              you have read our
              <a href={`https://hungama.com/privacy-policy`}>
                {" Privacy Policy "}
              </a>
            </p>
          </div>

          {/* Start Non App Pageview pixel Code */}
          <NonAppPageViewPixel />
          {/* End Non App Pageview pixel Code */}
        </div>
      )}
    </>
  );
};
