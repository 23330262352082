import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function SwiperSlider({ language }) {
  return (
    <div className="swiper-container swiper1">
      <div className="swiper-wrapper">
        <Swiper
          pagination={{ clickable: true }}
          className={"swiper1"}
          autoplay={{ delay: 3000 }}
        >
          {/* code for static banner */}
          <SwiperSlide>
            <div className="swiper-slide">
              <div className="slidiv">
                <div className="sliderrhtblur"></div>
                <div className="sliderbtmblur"></div>
                {/* <Image src={"sliderimg.png"} alt="sliderimg" />  */}
                <img
                  src={`https://images1.hungama.com/music_purchase_plan_1x.png`}
                  alt="sliderimg"
                />
                <div className="posslidcon">
                  <div className="goldicnslid"></div>
                  <h1 className="slidftxt">{language?.pay_banner_title_1}</h1>
                  <h2 className="slidstxt">
                    {language?.pay_banner_subtitle_1}
                  </h2>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <div className="slidiv">
                <div className="sliderrhtblur"></div>
                <div className="sliderbtmblur"></div>
                {/* <Image src={"sliderimg.png"} alt="sliderimg" />  */}
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    `/web/images/movie_banner_web.png`
                  }
                  alt="sliderimg"
                />
                <div className="posslidcon">
                  <div className="noaddslid"></div>
                  <h1 className="slidftxt">{language?.pay_banner_title_2}</h1>
                  <h2 className="slidstxt">
                    {language?.pay_banner_subtitle_2}
                  </h2>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
