import React from "react";
import ReactDOM from "react-dom";
import Hungama from "./hungama";
import ReactGA4 from "react-ga4";

/*import "./styles/iconmoon.css"
import "./styles/style.css"
import "./styles/swiper-bundle.css"
import "./styles/button-style.css"
import "./styles/custom.css"
*/
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-M22ZR8Z",
};
TagManager.initialize(tagManagerArgs);
const GA4_TRACKING_ID = `${process.env.REACT_APP_GA4_CODE}`; // OUR_GA4_TRACKING_ID
ReactGA4.initialize(GA4_TRACKING_ID);
ReactDOM.render(<Hungama />, document.getElementById("root"));
