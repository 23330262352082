import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId,
} from "./amplitude";
import { payUpi, checkUpiPaymentStatus } from "./payments";
import { SUCCESS, authKey } from "../../constants";
import {
  initMoengage,
  setMoengageUserId,
  sendMoengageDataPkg,
} from "./moengage";
import { rabbitMQReceiver,rabbitMQReceiver1,rabbitMQReceiver2,rabbitMQReceiver3,rabbitMQReceiverMoengage,rabbitMQReceiverMoengage1,rabbitMQReceiverMoengage2,rabbitMQReceiverMoengage3} from "../utils/services";
import { MD5 } from "./md5";

//amplitude with rabbitMQ
export const ampRabbitMQPageEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';
  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.event_properties.platform_id   // Modify platform if needed
  });
  rabbitMQReceiver(mqBody);
  rabbitMQReceiverMoengage(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver(mqBody2);
  } 
};

export const ampRabbitMQSuccessPageEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';
  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.event_properties.platform_id   // Modify platform if needed
  });
  rabbitMQReceiver1(mqBody);
  rabbitMQReceiverMoengage1(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver1(mqBody2);
  } 
};

export const ampRabbitMQFailurePageEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_pageview",
  };
  mqBody2['product_id']= 4;
  mqBody2.event_properties['product_id']= 4;

 
  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.platform_id   // Modify platform if needed
  });
  
  rabbitMQReceiver2(mqBody);
  rabbitMQReceiverMoengage2(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
   rabbitMQReceiver2(mqBody2);
  }
};

export const ampRabbitMQsubBtnClickPageEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_click",
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_click",
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';

  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };
  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.platform_id   // Modify platform if needed
  });
  rabbitMQReceiver3(mqBody);
  rabbitMQReceiverMoengage3(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver3(mqBody2);
  }
};

export const ampRabbitMQNewPageEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: param['Page Type'],
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: param['Page Type'],
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';
  rabbitMQReceiver(mqBody);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver(mqBody2);
  }

};

export const ampRabbitMQBtnEvent = (param, btnActionName) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { Action: btnActionName },
    event_type: "npay_click",
  };

  let mqBody2 = {
    ...param,
    event_properties: { Action: btnActionName },
    event_type: "npay_click",
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';

  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.platform_id  // Modify platform if needed
  });
  rabbitMQReceiver(mqBody);
  rabbitMQReceiverMoengage(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver(mqBody2);
  }
};

export const ampRabbitMQPopUpEvent = (param) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_popup",
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: "npay_popup",
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';

  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.platform_id   // Modify platform if needed
  });
  rabbitMQReceiver(mqBody);
  rabbitMQReceiverMoengage(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver(mqBody2);
  }
};

export const ampRabbitMQCustomEvent = (param, eventName) => {
  const storedWebsite = localStorage.getItem("website");
  let mqBody = {
    ...param,
    event_properties: { ...param },
    event_type: eventName,
  };

  let mqBody2 = {
    ...param,
    event_properties: { ...param },
    event_type: eventName,
  };
  mqBody2['product_id']= '4';
  mqBody2.event_properties['product_id']= '4';

  // Initialize mqBodyMoengage with a similar structure to mqBody
  let mqBodyMoengage = {
    ...param,
    event_type: mqBody.event_type,    // Same event type
    event_properties: []              // Initialize event_properties as an array
  };

  // Iterate over mqBody event_properties and create actionAttributes dynamically
  const actionAttributes = {};
  for (const key in mqBody.event_properties) {
    if (mqBody.event_properties.hasOwnProperty(key)) {
      actionAttributes[key] = mqBody.event_properties[key];
    }
  }

  // Push the actionAttributes into the event_properties array of mqBodyMoengage
  mqBodyMoengage.event_properties.push({
    action: mqBody.event_type,  // Use the event type for action
    attributes: actionAttributes,
    current_time: Date.now(),   // Add the current time
    // user_timezone_offset: new Date().getTimezoneOffset(),  // Current timezone offset
    platform: mqBody.platform_id  // Modify platform if needed
  });
  rabbitMQReceiver(mqBody);
  rabbitMQReceiverMoengage(mqBodyMoengage);
  if(storedWebsite == 'true' || storedWebsite == true){
    rabbitMQReceiver(mqBody2);
  }
};

//amplitude with package

export const amplitudeBtnEvent = (
  identity,
  event_type,
  productId,
  platformId
) => {
  /* amplitude code start*/
  initAmplitude(productId, platformId);
  const amp_param = {
    Action: event_type,
  };
  setAmplitudeUserId(identity);
  sendAmplitudeData("npay_click", amp_param);
  /* amplitude code end*/
};

export const amplitudePopUpEvent = (identity, param, productId, platformId) => {
  /* amplitude code start*/
  initAmplitude(productId, platformId);
  setAmplitudeUserId(identity);
  sendAmplitudeData("npay_popup", param);
  /* amplitude code end*/
};

export const amplitudePageEvent = (identity, param, productId, platformId) => {
  /* amplitude code start*/
  // sendMoengageData(identity,1,'BHARI',{'key':'value'})
  initAmplitude(productId, platformId);
  setAmplitudeUserId(identity);
  sendAmplitudeData("npay_pageview", param);
  /* amplitude code end*/
};

export const amplitudeCustomEvent = (
  identity,
  eventName,
  param,
  productId,
  platformId
) => {
  /* amplitude code start*/
  initAmplitude(productId, platformId);
  setAmplitudeUserId(identity);
  sendAmplitudeData(eventName, param);
  /* amplitude code end*/
};

//moengage

export const moengageBtnEvent = (identity, event_type) => {
  /* moengage code start*/
  initMoengage();
  const amp_param = {
    Action: event_type,
  };
  setMoengageUserId(identity);
  sendMoengageDataPkg("npay_click", amp_param);
  /* moengage code end*/
};

export const moengagePopUpEvent = (identity, param) => {
  /* moengage code start*/
  initMoengage();
  setMoengageUserId(identity);
  sendMoengageDataPkg("npay_popup", param);
  /* moengage code end*/
};

export const moengagePageEvent = (identity, param) => {
  /* moengage code start*/
  // sendMoengageData(identity,1,'BHARI',{'key':'value'})
  initMoengage();
  setMoengageUserId(identity);
  sendMoengageDataPkg("npay_pageview", param);
  // sendMoengageData(identity,1,'npay_pageview',param)
  /* moengage code end*/
};

export const moengageCustomEvent = (identity, eventName, param) => {
  /* moengage code start*/
  initMoengage();
  setMoengageUserId(identity);
  sendMoengageDataPkg(eventName, param);
  /* moengage code end*/
};

export const getParam = (location, param) => {
  return new URLSearchParams(location).get(param);
};

export const getUpiType = (upiType) => {
  // console.log(upiType)
  upiType = upiType.split("@")[1];
  const upi = "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/upi.svg";
  const gpay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/gpay.svg";
  const paytm =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/paytm.svg";
  const phonepay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/phonepay.svg";

  if (upiType.toLowerCase() === "paytm")
    return { upiType: paytm, upiName: "Paytm App" };
  if (upiType.toLowerCase() === "gpay")
    return { upiType: gpay, upiName: "Gpay App" };
  if (upiType.toLowerCase() === "ybl")
    return { upiType: phonepay, upiName: "PhonePe App" };
  return { upiType: upi, upiName: "UPI" };
};

export const payNow = (data, paymentsData, urlparams) => {
  // console.log(data);
  // amplitudeBtnEvent(
  //   paymentsData.formData.identity,
  //   "Btn_PayNowConfirmation_UPI"
  // );
  ampRabbitMQBtnEvent(
    { identity: paymentsData.formData.identity },
    "Btn_PayNowConfirmation_UPI"
  );

  const upi_type = data.upi_type.toLowerCase();
  const pg = "UPI";
  const bankcode = "INTENT";

  payUpi(
    upi_type,
    data,
    pg,
    bankcode,
    urlparams,
    paymentsData.formData.couponcode
  ).then((res) => {
    console.log(res.data.result);
    const { status, order_id, url } = res.data;
    if (status === SUCCESS) {
      if (order_id !== " ") {
        setTimeout(() => {
          checkUpiPayment(
            false,
            data.payment_id,
            data.plan_details_id,
            order_id,
            paymentsData
          );
        }, 10000);
      }
      window.location.href = url;
    } else {
      // history.push("/404");
    }
  });
};

export const checkUpiPayment = (
  refresh,
  payment_id,
  plan_details_id,
  order_id,
  paymentsData
) => {
  // console.log("payment_id"+payment_id+"plan_details_id"+plan_details_id+"txn"+txnid);
  if (refresh) {
    // amplitudeBtnEvent(identity, "Btn_RefreshVerification_UPI");
  }
  const { product_id, couponcode, identity } = paymentsData.formData;
  checkUpiPaymentStatus(
    payment_id,
    product_id,
    order_id,
    couponcode,
    identity
  ).then((res) => {
    if (res.status === SUCCESS) {
      // console.log(res);
      const { status } = res.data.transaction_details[order_id];
      const storedWebsite = localStorage.getItem("website");
   if (status === "failure") {
  
        if(storedWebsite == 'true' || storedWebsite == true){
 window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}&website=true`;
        }
        else{
 window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}`;
 }
      } else if (status === "success") {
        // console.log("enter in sucess");
        //     // amplitudePopUpEvent(identity, {
        //     //     "Source": utm_source,
        //     //     "Plan Name": planData.plan_name,
        //     //     "Plan Validity": planData.plan_valid,
        //     //     "Plan Value": planData.plan_price.toFixed(2),
        //     //     "Plan Currency": planData.plan_currency,
        //     //     "Plan ID": plan_id,
        //     //     "Affiliate ID": aff_id,
        //     //     "Action": "UPI_Confirm"

        //     // });
        if(storedWebsite == 'true' || storedWebsite == true){
         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}&website=true`;
        }
        else{
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${order_id}&plan_details_id=${plan_details_id}&coupon_code=${couponcode}&identity=${identity}`;

        }
      } else if (status === "pending") {
       setTimeout(() => {
          checkUpiPayment(
            false,
            payment_id,
            plan_details_id,
            order_id,
            paymentsData
          );
        }, 10000);
      }
    }
  });
};

// language converter for dynamic content
export const langConvrt = (str, defaultEnData, textData) => {
  // console.log("str", str, "endata:", defaultEnData, "txtData", textData);
  if (Object.keys(defaultEnData)?.length > 0 && str !== "") {
    let defaultkeyName = Object.keys(defaultEnData).find(
      (key) => str === defaultEnData[key]
    );

    return textData[defaultkeyName] ? textData[defaultkeyName] : "";
  }
};

export const generateAuthKey = (identity) => {
  let key = authKey + ":" + identity;
  // console.log("md5", MD5(key.trim()).toLowerCase());
  return MD5(key.trim()).toLowerCase();
};
