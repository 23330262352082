import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import { useLocation, useHistory } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { logData } from "../../../../utils/services";
import { ampRabbitMQPageEvent, getParam } from "../../../../utils";
// import { initAmplitude, sendAmplitudeData, setAmplitudeUserId } from '../../../utils/amplitude';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function PlengSubscriptionSuccess({
  orderData,
  order_id,
  txtData,
  lang,
}) {
  //check and open success page in window.top.location
  if (window.top.location.href !== window.location.href) {
    window.top.location.href = window.location.href;
  }

  function addDaysToISODate(dateStr, daysToAdd) {
    // Parse the date string to create a Date object
    let date = new Date(dateStr);
   
    // Add the specified number of days
    date.setDate(date.getDate() + daysToAdd);
   
    // Format the new date back to the ISO string format
    return date.toISOString();
  };

  orderData.nextDate = addDaysToISODate(orderData.added_on,30)
  console.log('------------date-------------',orderData.nextDate)
  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  const isABAPopup = getParam(location, "cardparam");
  const toneName = getParam(location, "tone_name")?getParam(location, "tone_name"):"tone name";
  // const platform_id=new URLSearchParams(location).get('platform_id');
  const offer =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/offer.png";
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // const useAnalyticsEventTracker = (category="Payment Init") => {
  //     const eventTracker = (action = "action init", label = "label init") => {
  //       ReactGA.event({category, action, label});
  //     }
  //     return eventTracker;
  //   }

  //   const gaEventTracker = useAnalyticsEventTracker('Post Payment Screens');
  //   let history = useHistory();
  // const goToPreviousPath=()=>{
  //     // history.goBack()
  //     if(platform_id==='1'){
  //         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
  //     }
  // }
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const identity = new URLSearchParams(location).get("identity");
  // const callback_url = new URLSearchParams(location).get('callback_url');
  let vouchers = [];
  const goToPreviousPath = () => {
    console.log('inside goback')
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/pages/response/success.php`;
  };
  // let voucherImages = [offer, offer, offer, offer];
  // for (let i = 0; i < voucherImages.length; i++) {
  //   vouchers.push(
  //     <SwiperSlide className="planSwiperSlider" key={i}>
  //       <li className="hg_item">
  //         <div className="mb15">
  //           <div className="hg_item_image_wrapper">
  //             {" "}
  //             <a href="#!">
  //               {" "}
  //               <img
  //                 className="hg_item_image"
  //                 src={
  //                   process.env.REACT_APP_ASSETS_URL +
  //                   "/mobile/images/offer.png"
  //                 }
  //                 style={{ width: "120px" }}
  //                 alt="topchart1"
  //               />{" "}
  //             </a>{" "}
  //           </div>
  //         </div>
  //       </li>
  //     </SwiperSlide>
  //   );
  // }
  var plan_currency_symbol = "";
  if (orderData?.currency === "USD") {
    plan_currency_symbol = "$";
  } else {
    plan_currency_symbol = "₹";
  }

  var URL = "";
  // console.log("sdsdf", typeof orderData?.platform_id, orderData?.platform_id);
  if (orderData?.platform_id ===1 || orderData?.platform_id === 4) {
    URL = "http://hungama.com/payment/success/";
  }

  // console.log("URL:", URL);

  const formatAMPM = (date) => {
    // console.log(date.toUTCString(),'date');
    var dateString = date;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(1, 4).join(" ");
    var dateStringArr = dateString.split(" ");
    //console.log(dateStringArr)
    // console.log(dateStringArr[0]+', '+dateStringArr[1]+' '+dateStringArr[2]);
    var mdDate =
      dateStringArr[0] + ", " + dateStringArr[1] + " " + dateStringArr[2];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return mdDate + " | " + strTime;
  };

  useEffect(() => {
    console.log('inside success page')
    // if (isLoadingOnce) {
    //   let source = "";
    //   if (
    //     orderData?.plan_type === "subscription" ||
    //     orderData?.plan_type === "event"
    //   ) {
    //     source = "PG Page_Subscription";
    //   }

    //   // amplitude with RabbitMQ
    //   ampRabbitMQPageEvent({
    //     identity: orderData?.identity,
    //     "Page Type": "PG Page_Success",
    //     Source: source,
    //     "Plan Name": orderData?.plan_name,
    //     "Plan Validity": orderData?.plan_valid,
    //     "Plan Value": orderData?.plan_amount,
    //     order_id: orderData?.order_id,
    //     "Page Language": lang ? lang : "en",
    //     product_id: orderData?.product_id,
    //     platform_id: orderData?.platform_id,
    //   });
    //   /* Frontend Logs */
    //   const lgData = {
    //     url: hostname + pathname,
    //     params: location,
    //     couponcode: couponcode,
    //     order_data: orderData,
    //     log_time: new Date().toLocaleString().replace(",", ""),
    //     log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
    //   };
    //   logData({ logData: JSON.stringify(lgData) }).then(() => {});
    // }
    setLoadingOnce(false);
  }, [
    order_id,
    orderData,
    isLoadingOnce,
    identity,
    couponcode,
    hostname,
    location,
    pathname,
    lang,
  ]);

  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          <div className="pay-back">
            <img
              src={
                process.env.REACT_APP_ASSETS_URL +
                "/mobile/pleng/images/back-arrow.svg"
              }
              onClick={() => {history.goBack()}}
              alt="arrow"
            />
          </div>
          <span>{txtData?.smart_tune_str_17}</span>
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div className="ticket-card">
              <div
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}+'/mobile/pleng/images/cover-img.png')`,
                }}
              ></div>
              <p>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/logo.svg"
                  }
                  alt="logo-confir-img"
                  className="logo-confir-img"
                />
              </p>
              <p>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/smart_tune_success.svg"
                  }
                  alt="confir-img"
                  className="confir-img"
                />
              </p>

              <h2 className="confir-title">
                {txtData?.smart_tune_str_18}!
              </h2>
              <p className="confir-genre">
                {txtData?.smart_tune_str_19}
              </p>
            </div>

            <div className="pay-mode-box pay-mode-bottom-p">
              <p className="p-t1">{txtData?.smart_tune_str_17}</p>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_21}
                </div>
                <div className="order-last-col">{orderData?.order_id}</div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_22}
                </div>
                <div className="order-last-col">{orderData?.tone_title}</div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_23}
                </div>
                <div className="order-last-col">{formatAMPM(new Date(orderData?.added_on))}</div>
              </div>
                      
              {/* {isActive && (
                <> */}
                  <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_24}
                </div>
                <div className="order-last-col">{txtData?.smart_tune_str_25}</div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_26}
                </div>
                <div className="order-last-col">{formatAMPM(new Date(orderData?.nextDate))}</div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.smart_tune_str_27}
                </div>
                <div className="order-last-col">{plan_currency_symbol}
                      0.{orderData?.amount}</div>
              </div>
                {/* </>
              )} */}
              {/* <div
                className="order-detail"
                onClick={() => setIsActive(!isActive)}
              > */}
                {/* <a className="view-more">View Less <img className="vm-down-arrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/down-arrow.svg"} alt="arrow"/></a> */}
                {/* <a href="javascript:;" class="view-more">View More <img class="vm-down-arrow" src="images/down-arrow.svg" alt="arrow"/></a> */}
                {/* {isActive === true && (
                  <div className="view-more">
                    {txtData?.view_less}
                    <img
                      className="vm-down-arrow"
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/arrow-up.svg"
                      }
                      alt="arrowup"
                    />
                  </div>
                )} */}

                {/* {isActive === false && (
                  <div className="view-more">
                    {txtData?.view_more}
                    <img
                      className="vm-down-arrow"
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/down-arrow.svg"
                      }
                      alt="arrowdown"
                    />
                  </div>
                )} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-action">
        <a  onClick={() =>
                        goToPreviousPath()
                      }
           className="button-blue d-block">
          {txtData?.smart_tune_str_28}
        </a>
      </div>
    </div>
  );
}
