import React from "react";
export function Image({ src, alt, className, style }) {
    return (
        <img src={process.env.REACT_APP_ASSETS_URL + `/web/images/${src}`} alt={alt} style={style} className={className} />
    )
}

export function LI({ src, alt }) {
    return (
        <img src={process.env.REACT_APP_ASSETS_URL + `/web/images/${src}`} alt={alt} />
    )
}