import React from "react";
import ReactGA from "react-ga";
import { Image } from "../../subcomponents/Elements";
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../../utils";

export default function Coins({
  urlparams,
  paymentsData,
  updateMultipleFormData,
}) {
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Hungama Coins");

  // console.log(paymentsData);
  const payUsingCoins = (data) => {
    gaEventTracker("redeem_now", paymentsData?.data?.planInfo?.plan_name);
    // amplitudeBtnEvent(paymentsData.formData.identity, "Btn_Submit_Coins");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_Submit_Coins"
    );
    if (
      paymentsData?.data?.planInfo?.plan_price >=
      paymentsData?.data?.total_user_coins
    ) {
      updateMultipleFormData({ reedemCoinsFailed: true });
      return;
    }
    const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=NB&bankcode=${data?.bank_code}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}`;
    window.location.href = url;
  };
  return (
    <div
      id="coins"
      className="tabcontent"
      style={{
        display:
          paymentsData?.formData?.tabToShow === "coins" ? "block" : "none",
      }}
    >
      {paymentsData?.data?.payments.map(
        (data, i) =>
          data.payment_name === "Hungama Coins" && (
            <div className="coins-box" id="redeem-coin-box" key={i}>
              <p className="coins-tittle">
                {paymentsData?.language?.pay_coin_page_redeem_text}
              </p>
              <div className="coins-desc-box">
                <div className="coins-desc-box">
                  {Number(
                    paymentsData?.data?.payments[i]?.coins.replace(/,/g, "")
                  ) > Number(paymentsData?.data?.total_user_coins) ? (
                    <p className="coins-desc">
                      {paymentsData?.language?.pay_coin_page_desc_1}
                      {Number(
                        paymentsData?.data?.payments[i]?.coins.replace(/,/g, "")
                      ) - Number(paymentsData?.data?.total_user_coins)}
                      {paymentsData?.language?.pay_coin_page_desc_2}
                      <br />
                      {paymentsData?.language?.pay_coin_page_desc_3} <br />
                      {paymentsData?.language?.pay_coin_page_desc_4}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="my-coins-box">
                <p className="mycooins-txt">
                  {paymentsData?.language?.pay_my_coin_text}
                </p>
                <div className="my-coins">
                  <Image src="gold-coin-new.svg" className="coins-icon" />
                  {paymentsData?.data?.total_user_coins}
                </div>
              </div>
              <div className="redeem-btn-box">
                <span
                  className="payment-btn"
                  onClick={(e) => payUsingCoins(data)}
                >
                  <Image src="redeem-now.svg" className="redeem-icon" />
                  {paymentsData?.language?.pay_redeem_now_text}
                </span>
              </div>
            </div>
          )
      )}
    </div>
  );
}
