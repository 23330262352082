import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import { amplitudePageEvent } from "../../utils";
import { language } from "../../utils/services";
import { Paymentfailed } from "./paymentfailed";
// import { Paymentpending } from "./paymentpending";

export function MobilePaymentFailed() {
  const [isLoading, setLoading] = useState(true);
  const [txtData, setTextData] = useState({});
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const lang = new URLSearchParams(location).get("lang");
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const [orderData, setOrderData] = useState([]);
  const identity = new URLSearchParams(location).get("identity");
  // const callback_url = new URLSearchParams(location).get('callback_url');

  const formatAMPM = (date) => {
    // console.log(date.toUTCString(),'date');
    var dateString = date;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(1, 4).join(" ");
    var dateStringArr = dateString.split(" ");
    //console.log(dateStringArr)
    // console.log(dateStringArr[0]+', '+dateStringArr[1]+' '+dateStringArr[2]);
    var mdDate =
      dateStringArr[0] + ", " + dateStringArr[1] + " " + dateStringArr[2];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return mdDate + " | " + strTime;
  };

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };
      const body = {
        order_id: order_id,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/user/check_order`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          if (res?.data) {
            const orderData = res?.data?.data?.order_data;
            setOrderData(orderData);
            // console.log("ORDER_DATA:", res?.data?.data?.order_data);
            let pageType = "",
              source = "";

            if (
              res?.data?.data?.order_data.order_status === "5" ||
              res?.data?.data?.order_data.order_status === 5
            ) {
              pageType = "PG Page_Failed";
            } else if (
              res?.data?.data?.order_data.order_status === "2" ||
              res?.data?.data?.order_data.order_status === 2
            ) {
              pageType = "PG Page_Pending";
            }

            if (
              res?.data?.data?.order_data.content_details.plan_dname === "Rent"
            ) {
              source = "PG Page_TVOD";
            } else {
              source = "PG Page_Subscription";
            }

            /*
            amplitudePageEvent(
              identity,
              {
                "Page Type": pageType,
                Source: source,
                "Plan Name": orderData?.plan_name,
                "Plan Validity": orderData?.plan_valid,
                "Plan Value": orderData?.plan_amount,
                order_id: order_id,
              },
              product_id,
              platform_id
            );
            */
          }
        })
        .catch((err) => {
          //   console.log(err);
        });

      //language
      language(lang).then((res) => {
        //set Lang conditionally
        // setTextData(res);
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res?.secondLang : res?.default);
      });
    }
    setLoading(false);
  }, [isLoading, identity, order_id, lang]);
  //   console.log("MobilePaymentFailed orderData: ", orderData);
  return (
    <>
      {isLoading && <div className="loading">Loading...</div>}
      {!isLoading &&
        (orderData.order_status === "5" || orderData.order_status === 5) && (
          <Paymentfailed
            txtData={txtData}
            lang={lang}
            platform_id={platform_id}
            orderData={orderData}
            formatAMPM={formatAMPM}
          />
        )}
      {/* {!isLoading &&
        (orderData.order_status === "2" || orderData.order_status === 2) && (
          <Paymentpending orderData={orderData} formatAMPM={formatAMPM} />
        )} */}
    </>
  );
}
