export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
  "api-key": process.env.REACT_APP_API_KEY,
};

export const netBankArray = [
  {
    bankcode: "AIRNB",
    bankName: "Airtel Payments Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/airtel_payments_bank.svg",
  },
  {
    bankcode: "AXIB",
    bankName: "Axis Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/axis_bank.svg",
  },
  {
    bankcode: "BOIB",
    bankName: "Bank Of India",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/bank_of_india.svg",
  },
  {
    bankcode: "BOMB",
    bankName: "Bank Of Maharashtra",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/bank_of_maharastra.svg",
  },
  {
    bankcode: "CABB",
    bankName: "Canara Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/canara_bank.svg",
  },
  {
    bankcode: "CSBN",
    bankName: "Catholic Syrian Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/catholic_syrian_bank.svg",
  },
  {
    bankcode: "CBIB",
    bankName: "Central Bank of India",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/central_Bank_of_india.svg",
  },
  {
    bankcode: "CUBB",
    bankName: "City Union Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/city_union_bank.svg",
  },
  {
    bankcode: "CSMSNB",
    bankName: "Cosmos Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/cosmos_bank.svg",
  },
  {
    bankcode: "DCBCORP",
    bankName: "DCB Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/dcb_bank.svg",
  },
  //{ bankcode: 'DENN', bankName: 'Dena Bank' },
  {
    bankcode: "DSHB",
    bankName: "Deutsche Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/deutsche_bank.svg",
  },
  {
    bankcode: "DLSB",
    bankName: "Dhanlaxmi Bank - Retail",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/dhanlaxmi_bank_retail.svg",
  },
  {
    bankcode: "FEDB",
    bankName: "Federal Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/federal_bank.svg",
  },
  {
    bankcode: "HDFB",
    bankName: "HDFC Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/hdfc_bank.svg",
  },
  {
    bankcode: "ICIB",
    bankName: "ICICI Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/icici_bank.svg",
  },
  {
    bankcode: "IDFC",
    bankName: "IDFC FIRST Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/idfc_first_bank.svg",
  },
  {
    bankcode: "INDB",
    bankName: "Indian Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/indian_bank.svg",
  },
  {
    bankcode: "INOB",
    bankName: "Indian Overseas Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/indian_overseas bank.svg",
  },
  {
    bankcode: "INIB",
    bankName: "IndusInd Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/induslnd_bank.svg",
  },
  {
    bankcode: "JAKB",
    bankName: "Jammu and Kashmir Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/jammu_and_kasmir_bank.svg",
  },
  {
    bankcode: "JSBNB",
    bankName: "Janata Sahakari Bank Pune",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/janata_sahakari_bank_pune.svg",
  },
  {
    bankcode: "KRKB",
    bankName: "Karnataka Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/karnataka_bank.svg",
  },
  {
    bankcode: "KRVB",
    bankName: "Karur Vysya Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/karur_vysya_bank.svg",
  },
  {
    bankcode: "KRVBC",
    bankName: "Karur Vysya - Corporate Netbanking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/karur_vysya_corporate_net_banking.svg",
  },
  {
    bankcode: "162B",
    bankName: "Kotak Mahindra Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/kotak_mahindra_bank.svg",
  },
  {
    bankcode: "LVCB",
    bankName: "Lakshmi Vilas Bank - Corporate Netbanking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/lakshmi_vilas_bank_corporate_banking.svg",
  },
  {
    bankcode: "LVRB",
    bankName: "Lakshmi Vilas Bank - Retail Netbanking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/lakshmi_vilas_bank_retail_banking.svg",
  },
  {
    bankcode: "OBCB",
    bankName: "Oriental Bank of commerce",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/obc_bank.svg",
  },
  {
    bankcode: "PNBB",
    bankName: "Punjab National Bank - Retail Banking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/punjab_national_bank_corporate_banking.svg",
  },
  {
    bankcode: "CPNB",
    bankName: "Punjab National Bank - Corporate Banking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/punjab_national_bank_retail_banking.svg",
  },
  {
    bankcode: "SRSWT",
    bankName: "Saraswat bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/saraswat_bank.svg",
  },
  {
    bankcode: "SOIB",
    bankName: "South Indian Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/south_indian_bank.svg",
  },
  {
    bankcode: "SBIB",
    bankName: "State Bank of India",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/sbi.svg",
  },
  {
    bankcode: "SVCNB",
    bankName: "Shamrao Vithal Co-operative Bank Ltd",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/svc_bank.svg",
  },
  {
    bankcode: "SYNDB",
    bankName: "Syndicate Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/syndicate_bank.svg",
  },
  {
    bankcode: "TMBB",
    bankName: "Tamilnad Mercantile Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/tamilnad_mercantile_bank.svg",
  },
  {
    bankcode: "TBON",
    bankName: "The Nainital Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/the_nainital_bank.svg",
  },
  {
    bankcode: "UCOB",
    bankName: "UCO Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/uco_bank.svg",
  },
  {
    bankcode: "UBIB",
    bankName: "Union Bank Of India",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/union_bank.svg",
  },
  {
    bankcode: "UBIBC",
    bankName: "Union Bank - Corporate Netbanking",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/union_bank_corporate_banking.svg",
  },
  {
    bankcode: "UNIB",
    bankName: "United Bank of India",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/united_bank_of_india.svg",
  },
  {
    bankcode: "YESB",
    bankName: "Yes Bank",
    bankLogo:
      "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/bank_icons/yes_bank.svg",
  },
];

export const SUCCESS = "success";
export const FAILED = "failed";

//free trial plan data
export const freeTrialPlanData = {
  isActive: false,
  plan_id: "3",
  plan_amt: "1",
  country: "IN",
  product_id: "1",
  freeTrialPlanRecText: "Trial Offer",
  freeTrialPaymentPageText: [
    "1. Subscription will be auto renewed at Rs. 99 per month post trial period.",
    "2. You can cancel subscription anytime.",
  ],
};

export const defaultPlans = {
  data: {
    plans: [],
    productInfo: {},
    features: [],
    sliderInfo: [],
  },
  formData: {
    plan_id: "",
    couponCode: "", //HUN7E12D87
    couponValidStatus: false,
    validStatus: false,
    sideBarMinimize: false,
    orderData: {
      plan_type: "",
      order_status: "",
    },
  },
  language: {},
  defaultlang: {},
};

export const defaultPayments = {
  data: {
    pageTitle: "",
    pageDescription: "",
    productInfo: {},
    planInfo: {
      plan_price: "",
    },
    couponInfo: {},
    featuredPayment: [],
    payments: [],
    walletPayments: [],
    upiPayments: [],
    netBankingPayments: [],
    total_user_coins: "",
  },
  formData: {
    plan_id: "",
    upiId: "",
    couponcode: "", //HUN7E12D87
    couponValidStatus: "",
    tabToShow: "preferred",
    showallbank: false,
    verifyPayPopUp: false,
    payPopUp: false,
    IsUpiValid: false,
    upiType: "",
    upiName: "",
    isTimer: false,
    enableUpiError: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardType: "",
    cardCategory: "",
    reedemCoinsFailed: false,
  },
  language: {},
  defaultlang: {},
};

//Pleng Test Array
export let plengTestUserIds = ["1140565087", "1258820066", "1061554768"];

//HM UN Test Array
export let HmTestUserIds = [""];

// Export auth key.
export let authKey = "PAYUNHUNGAMA#$2021";

// Export auth key.
export let HM_APP_DEEPLINK = "https://hmusic.onelink.me/0G2f/NAS";
