export const plansReducer = (draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case "plansData":
      draft.data = payload;
      return;
    case "language":
      draft.language = payload;
      return;
    case "defaultlang":
      draft.defaultlang = payload;
      return;
    case "applyCouponCode":
      // draft.data.plans = discountPlans(payload.data, payload.res);
      // draft.formData.couponValidStatus = true;
      let response = discountPlans(payload.data, payload.res);
      draft.data.plans = response?.new_plans;
      draft.formData.couponValidStatus = response?.is_coupon_valid;
      draft.formData.isCouponExpired = response?.is_coupon_expired;
      draft.formData.freeCouponData = response?.freeCouopnData;

      return;
    case "couponCodeCancel":
      // draft.data.plans = discountPlans(payload.data, payload.type);
      // draft.formData.couponValidStatus = false;
      return;
    case "updateMultipleFormData":
      for (var key in payload) {
        draft.formData[key] = payload[key];
      }
      return;
    default:
      break;
  }
};

export const paymentsReducer = (draft, action) => {
  // console.log(action);
  const { type, payload } = action;
  switch (type) {
    case "paymentsData":
      draft.data = payload;
      draft.data.payments = payload.payments.sort(
        (a, b) => parseFloat(a.display_order) - parseFloat(b.display_order)
      );
      return;
    case "language":
      draft.language = payload;
      return;
    case "applyCouponCode":
      let response = discountPayments(payload.data, payload.res);
      draft.formData.couponValidStatus = response?.is_coupon_valid;
      draft.formData.isCouponExpired = response?.is_coupon_expired;
      draft.formData.freeCouponData = response?.freeCouopnData;
      draft.formData.discountedPrice = response?.discountedPrice;
      draft.formData.CouponNotApplicableForPlan =
        response?.CouponNotApplicableForPlan;

      /* old  logic*/
      // draft.formData.discountedPrice = discountPayments(
      //   payload.data,
      //   payload.res
      // );
      return;
    case "updateMultipleFormData":
      for (var key in payload) {
        draft.formData[key] = payload[key];
      }
      return;
    default:
      break;
  }
};

function discountPlans(data, action) {
  //console.log("ACTION::", action, "data.plans", data?.plans);
  //console.log("action.status::",action?.status,"datatype:",typeof action?.status);
  let newPlans = [];
  let response = {
    new_plans: [],
    is_coupon_expired: false,
    is_coupon_valid: false,
    freeCouopnData: {},
  };

  // status 2 is for special free coupon for Half Yearly Plan Asssign via coupon
  if (
    (action?.status !== "" && action?.status === 1) ||
    (action?.status !== "" && action?.status === 2)
  ) {
    let discounted,
      pair = {},
      // eslint-disable-next-line no-unused-vars
      showActive = false;
    data.plans.forEach((e) => {
      if (
        action?.coupon_details?.type === "discounted" &&
        action?.coupon_details?.plansApplicable?.includes(`${e?.plan_id}`)
      ) {
        discounted = Math.round(
          Number(e.plan_price) -
            (Number(action?.coupon_details?.value) / 100) * Number(e.plan_price)
        );
        if (discounted > 0) {
          response.is_coupon_valid = true;
          pair = { discountedPrice: discounted, showActive: true };
        }
      } else if (
        action?.coupon_details?.type === "flat" &&
        action?.coupon_details?.plansApplicable.includes(`${e?.plan_id}`)
      ) {
        discounted =
          Number(e.plan_price) - Number(action?.coupon_details?.value);
        if (discounted > 0) {
          response.is_coupon_valid = true;
          pair = { discountedPrice: discounted, showActive: true };
        }
      } else if (
        action?.coupon_details?.type === "free" &&
        action?.coupon_details?.plansApplicable.includes(`${e?.plan_id}`)
      ) {
        discounted = Number(action?.coupon_details?.value)?.toFixed();
        // console.log("its free", discounted, "plan", e);
        response.is_coupon_valid = true;
        pair = { discountedPrice: discounted, showActive: true };
        //set freecoupon data
        response.freeCouopnData = action;
      } else if (action.type === "cancel") {
        discounted = "";
      } else {
        response.is_coupon_valid = true;
        pair = { showActive: false };
      }
      //Handling Zero or minus value
      // let pair = {};
      // if (discounted <= 0 && action?.type !== "free") {
      //   pair = {};
      // } else if (discounted <= 0 && action?.type === "free") {
      //   pair = { discountedPrice: discounted, is_recommended: true };
      // } else {
      //   pair = { discountedPrice: discounted };
      // }
      e = { ...e, ...pair };
      newPlans.push(e);
    });
    //set newplans
    response.new_plans = newPlans;
  } else if (action?.status === 0) {
    response.is_coupon_valid = false;
    response.new_plans = data?.plans;
  } else {
    response.is_coupon_expired = true;
    response.is_coupon_valid = false;
    response.new_plans = data?.plans;
  }
  // console.log("response::", response);
  return response;
}

function discountPayments(data, res) {
  // console.log("data::", data, "res::", res);
  //console.log("Data::", data, "res::", res);
  //console.log("data.plan_id", data?.plan_id, typeof data?.plan_id);
  let response = {
    discountedPrice: "",
    is_coupon_expired: "",
    is_coupon_valid: "",
    freeCouopnData: {},
    CouponNotApplicableForPlan: false,
  };
  let discountedPrice;
  if (
    (res?.status !== "" && res?.status === 1) ||
    (res?.status !== "" && res?.status === 2)
  ) {
    if (
      res?.coupon_details?.type === "discounted" &&
      res?.coupon_details?.plansApplicable?.includes(`${data?.plan_id}`)
    ) {
      discountedPrice = Math.round(
        Number(data?.plan_price) -
          (Number(res?.coupon_details?.value) / 100) * Number(data?.plan_price)
      );
      if (discountedPrice > 0) {
        response.is_coupon_valid = true;
        response.discountedPrice = discountedPrice;
      }
    } else if (
      res?.coupon_details?.type === "flat" &&
      res?.coupon_details?.plansApplicable.includes(`${data?.plan_id}`)
    ) {
      discountedPrice = Math.round(
        Number(data?.plan_price) - Number(res?.coupon_details?.value)
      );
      if (discountedPrice > 0) {
        response.is_coupon_valid = true;
        response.discountedPrice = discountedPrice;
      }
    } else if (res?.coupon_details?.type === "free" && res?.status === 2) {
      //special coupon for amazone, direct success/fail page
      response.is_coupon_valid = true;
      if (res?.status === 2 && res?.coupon_details?.type === "free") {
        // response.is_coupon_valid = true;
        // Patch :: Assign Half_yearly Plan via coupon code and go directly to resp success/fail page
        let obj = {
          couponCode: res?.coupon_code,
          status: res?.status,
          ...res?.coupon_details,
        };
        let { couponCode, payment_id, plan_details_id, plan_id } = obj;

        return (window.location.href = `${
          process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${data?.urlparams?.replace(
          "?",
          ""
        )}`);
      }
    } else if (
      res?.coupon_details?.type === "free" &&
      res?.coupon_details?.plansApplicable.includes(`${data?.plan_id}`)
    ) {
      // discountedPrice = Number(res?.coupon_details?.value)?.toFixed();
      // // console.log("its free", discountedPrice, "plan", data);
      // response.is_coupon_valid = true;
      // //set freecoupon data or send
      // response.freeCouopnData = res;
      // response.discountedPrice = discountedPrice;

      //create direct order
      let { plan_details_id, payment_id } = res?.coupon_details;
      //delete existing coupon if any
      // data?.urlparams?.delete("couponcode");

      // console.log(
      //   "free url pay:",
      //   `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&type=charge&plan_id=${data?.plan_id}&coupon_code=${res?.coupon_code}&${data?.urlparams}`
      // );
      return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${data?.plan_id}&coupon_code=${res?.coupon_code}&${data?.urlparams}`);
    } else if (res.type === "cancel") {
      discountedPrice = "";
    } else {
      response.is_coupon_valid = false;
      response.CouponNotApplicableForPlan = true;
      response.is_coupon_expired = false;
      console.log("not aplicable");
    }
  } else if (res?.status === 0) {
    response.is_coupon_valid = false;
    response.discountedPrice = "";
    response.is_coupon_expired = false;
  } else {
    console.log("expired.");
    response.is_coupon_expired = true;
    response.is_coupon_valid = false;
    response.discountedPrice = "";
  }
  // if (res.type === "discounted") {
  //   discountedPrice = data.plan_price - (res.value / 100) * data.plan_price;
  // } else if (res.type === "flat") {
  //   discountedPrice = data.plan_price - res.value;
  // }
  // return discountedPrice?.toFixed(2) > 0 ? discountedPrice?.toFixed(2) : "";

  return response;
}
